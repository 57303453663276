import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSoilLandfillCompactor() {
  return (
    <>
      <Helmet>
        <title>
          A32 Soil / Landfill Compactor Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Enroll in our CPCS Soil / Landfill Compactor (A32) Training Course to master the operation of soil and landfill compactors with certification upon completion."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A32 Soil / Landfill Compactor Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is aimed at individuals who require
              formal qualification for operating a Soil / Landfill Compactor.
              The course is tailored to meet the needs of both beginners and
              experienced operators. We conduct a learner analysis to determine
              the appropriate training duration, with options for those with
              limited experience and a two-day course for experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding of the sector and the role and responsibilities of
                a plant operator.
              </li>
              <li>
                Knowledge of principal components, basic construction, controls,
                and terminology of the compactor.
              </li>
              <li>
                Conformity with manufacturer’s requirements, operator’s
                handbook, and relevant regulations and legislation.
              </li>
              <li>
                Pre-use checks and setting up the machine for site travel.
              </li>
              <li>
                Operating the compactor over various terrains, including rough
                ground, inclines, and confined spaces.
              </li>
              <li>
                Compacting waste materials and leveling soils for covering
                purposes.
              </li>
              <li>
                Understanding the principles of compaction and the requirements
                for waste-material compaction.
              </li>
              <li>Safe shutdown and securing procedures for the machine.</li>
              <li>Loading and unloading procedures for machine transport.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of classroom-based teaching and
              practical exercises, followed by a one-day assessment involving
              CPCS technical tests to evaluate the learner’s competency in
              operating the compactor.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Learners who successfully pass the CPCS technical tests will
              receive a Red CPCS Trained Operator Card, valid for two years. To
              obtain the Blue CPCS Competence Card, learners must gain on-site
              experience and complete an NVQ Level 2 in Plant Operations within
              two years.
            </p>
          </div>

          <div className="entry-requirements">
            <h2>Entry Requirements</h2>
            <p>
              Learners must have passed the Operatives Health, Safety, and
              Environment Test within the last 2 years before the course start
              date. A good understanding of spoken and written English is also
              required.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
