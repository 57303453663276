import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function PlantLoaderSecurer() {
  return (
    <>
      <Helmet>
        <title>N120 Plant Loader Securer Course | Alpha Constructions</title>
        <meta
          name="description"
          content="NPORS-certified training for loading and unloading mobile plant. Covers industry safety, hazard management, and practical skills for securing machinery."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N120 Plant Loader Securer Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course provides essential training for individuals seeking a
              formal qualification in loading and unloading mobile plant. It
              combines theoretical knowledge and practical skills to ensure safe
              and efficient operations in compliance with industry standards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understand the industry, its risks, and operator
                responsibilities.
              </li>
              <li>
                Comprehend the manufacturer’s handbook and relevant legislation.
              </li>
              <li>Identify transport types, their components, and controls.</li>
              <li>
                Conduct pre-operational and safety checks in compliance with
                regulations.
              </li>
              <li>Prepare transporters for loading and unloading.</li>
              <li>
                Recognize hazards in the work area and learn mitigation
                strategies.
              </li>
              <li>Use effective communication methods on-site.</li>
              <li>Load and secure plant items on transport vehicles safely.</li>
              <li>Perform end-of-shift and shut-down procedures.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>Participants will be evaluated through:</p>
            <ul>
              <li>
                **NPORS Theory Assessment**: Open questions and multiple choice.
              </li>
              <li>
                **NPORS Practical Assessment**: Hands-on evaluation of skills.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants can receive one of the
              following NPORS cards:
            </p>
            <ul>
              <li>
                **Trained Operator Card (RED)**: Valid for 2 years for those
                without an NVQ. NVQ completion within this period is required to
                upgrade.
              </li>
              <li>
                **Competent Operator Card (BLUE)**: Valid for 5 years for those
                with the requisite NVQ, including a CPD logbook for continuous
                professional development.
              </li>
            </ul>
            <p>
              Applicants must pass the CITB Health, Safety & Environmental test
              within 2 years prior to applying for a card.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS Plant Loader Securer training equips participants with
              the skills and knowledge required to handle and secure plant
              machinery safely. It is ideal for operators at any skill level and
              adheres to industry standards for safety and operational
              efficiency.
            </p>
          </div>

          <div className="about-npors">
            <h2>About NPORS</h2>
            <p>
              Established in 1992, the National Plant Operators Registration
              Scheme (NPORS) is a leading accreditation body across multiple
              sectors, including Construction, Industrial, Warehousing, and
              Marine. NPORS provides flexibility while maintaining national and
              international standards through collaboration with accredited
              training providers and industry partners.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
