import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function RearTippingDumper() {
  return (
    <>
      <Helmet>
        <title>N205 Rear Tipping Dumper Course | Alpha Constructions</title>
        <meta
          name="description"
          content="NPORS Rear Tipping Dumper training for safe and productive operation. Designed for operators in construction and allied industries."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N205 Rear Tipping Dumper Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course provides participants with the skills and knowledge
              required to operate a rear tipping dumper safely and efficiently.
              Designed for new and experienced operators, it ensures compliance
              with industry safety standards and promotes operational
              excellence.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understand the construction industry, associated dangers, and
                operator responsibilities.
              </li>
              <li>
                Familiarize with the manufacturer’s handbook for rear tipping
                dumpers.
              </li>
              <li>
                Identify and explain the major components and functions of the
                machine.
              </li>
              <li>Locate and understand key controls and their functions.</li>
              <li>
                Perform pre-operational checks in line with legislative and
                manufacturer standards.
              </li>
              <li>
                Ensure appropriate PPE is identified and maintained for safe
                operation.
              </li>
              <li>Conduct safety checks at the work area.</li>
              <li>
                Prepare the rear tipping dumper for use and operate it safely
                and efficiently.
              </li>
              <li>
                Understand environmental considerations related to operation.
              </li>
              <li>
                Master loading and unloading procedures for transportation.
              </li>
              <li>
                Complete end-of-shift shutdown and secure parking procedures.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>Participants will undergo:</p>
            <ul>
              <li>
                NPORS Theory Assessment: Open and multiple-choice questions.
              </li>
              <li>
                NPORS Practical Assessment: Hands-on demonstration of skills.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful participants will receive one of the following
              certifications:
            </p>
            <ul>
              <li>
                Trained Operator Card (No NVQs Held): Valid for two years.
                Operators must achieve the requisite NVQs within this period to
                retain their certification.
              </li>
              <li>
                Competent Operator Card (NVQs Held): Valid for five years and
                includes a CPD record/logbook.
              </li>
            </ul>
            <p>
              Operators are required to pass the CITB Health, Safety &
              Environmental test within two years prior to applying for
              certification.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS Rear Tipping Dumper course equips participants with the
              necessary skills to safely operate rear tipping dumpers. This
              training ensures adherence to industry regulations and enhances
              workplace productivity for operators in construction and allied
              industries.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
