import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPlantMarshaller() {
  return (
    <>
      <Helmet>
        <title>A73 Plant and Vehicle Marshaller Course</title>
        <meta
          name="description"
          content="Develop the skills and knowledge needed to guide plant and vehicles safely with the Plant and Vehicle Marshaller (A73) course at Alpha Construction Training. Achieve CPCS certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A73 Plant and Vehicle Marshaller Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is tailored to provide participants with the expertise
              required to safely guide and communicate with machinery operators.
              It focuses on understanding marshalling procedures, adhering to
              site safety, and effectively managing vehicles in construction
              environments.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Gaining a comprehensive understanding of traffic management
                principles and site-specific rules.
              </li>
              <li>
                Recognizing hazards associated with plant and vehicle movement
                and how to mitigate risks.
              </li>
              <li>
                Utilizing hand signals and communication devices to guide
                vehicles effectively.
              </li>
              <li>
                Ensuring compliance with legal and manufacturer requirements.
              </li>
              <li>
                Conducting pre-operational checks and ensuring equipment
                readiness.
              </li>
              <li>
                Implementing effective signalling methods during reversing,
                loading, and offloading operations.
              </li>
              <li>
                Adhering to site rules while working near excavations,
                underground, and overhead hazards.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will be evaluated through a combination of
              theoretical tests and practical assessments to verify their
              competency in marshalling vehicles and understanding safety
              procedures. The course includes the CPCS technical test as a final
              assessment.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive the CPCS Red Trained Operator
              Card for Plant and Vehicle Marshaller (A73). The card is valid for
              two years, and participants can progress to the CPCS Blue
              Competence Card by completing the required NVQ Level 2.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The Plant and Vehicle Marshaller (A73) course offers essential
              training in traffic management, vehicle signalling, and site
              safety practices. It equips participants with the skills to ensure
              the safe movement of vehicles and machinery on construction sites.
            </p>
            <p>
              Enroll now to gain industry-recognized certification and open
              doors to new career opportunities in plant operation and
              construction safety.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
