import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPilingRigBoredAbove20Tonnes() {
  return (
    <>
      <Helmet>
        <title>
          A48 Piling Rig Bored Above 20T Course | Alpha Construction
        </title>
        <meta
          name="description"
          content="Join our CPCS Piling Rig – Bored Above 20 Tonnes A48 Training Course to master piling rig operations, focusing on rigs bored above 20 tonnes, with certification upon completion."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A48 Piling Rig Bored Above 20T Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Embark on a journey to master the intricacies of operating a
              piling rig with our “Piling Rig – Bored Above 20 Tonnes A48
              course.” This course blends theory and practice to ensure
              proficiency in piling rig operations, particularly rigs bored
              above 20 tonnes. It is meticulously crafted to ensure safety and
              compliance in all operations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>Roles and responsibilities of a piling rig operator.</li>
              <li>
                Understanding different types of piling rigs and their
                applications.
              </li>
              <li>Adhering to manufacturer’s requirements and legislation.</li>
              <li>
                Performing pre-use checks and ensuring safe operation practices.
              </li>
              <li>Configuring machinery for travel and operation.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes both theoretical and practical assessments.
              This ensures participants not only understand the principles of
              operating a piling rig but also gain the hands-on experience
              required to safely manage these machines.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will receive a recognized
              certification validating their proficiency in piling rig
              operations. This certification is widely acknowledged within the
              construction industry and will enhance career prospects.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Piling Rig – Bored Above 20 Tonnes A48 Course equips you
              with the skills and knowledge needed to operate heavy machinery
              safely and efficiently. The course covers theoretical knowledge,
              safety protocols, and hands-on training in handling piling rigs,
              ensuring you are job-ready upon completion.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
