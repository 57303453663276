import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSReachTruckForklift() {
  return (
    <>
      <Helmet>
        <title>
          A18 Reach Truck Forklift Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Gain formal qualifications with the A18 CPCS Reach Truck Forklift training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A18 Reach Truck Forklift Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is aimed at people who require a formal
              qualification for operating a Reach Truck Forklift. Training
              duration will be determined through a learner analysis to
              accommodate both beginners and experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Describe the nature of the sector and your role and
                responsibilities as a plant operator.
              </li>
              <li>
                Name and explain the purpose of principal components, basic
                construction, controls, and terminology.
              </li>
              <li>
                Conform to manufacturer requirements as outlined in the
                operator’s handbook and other relevant regulations.
              </li>
              <li>Perform all pre-use checks effectively.</li>
              <li>
                Explain the need for and function of appropriate documentation.
              </li>
              <li>
                Configure and set the forklift for travel, including confined
                space manoeuvring.
              </li>
              <li>
                Prepare for lifting and transferring duties safely and
                efficiently.
              </li>
              <li>
                Lift and remove various loads, including those at full working
                height.
              </li>
              <li>Place and remove loads accurately at specified locations.</li>
              <li>
                Maintain safe and tidy workspaces, addressing hazards and
                adhering to safety protocols.
              </li>
              <li>
                Carry out shut-down and securing procedures for transport.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes classroom-based teaching and practical
              exercises, followed by a one-day assessment consisting of CPCS
              technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Learners will receive a Red CPCS Trained Operator Card upon
              successfully passing the technical tests, valid for two years. To
              upgrade to a Blue CPCS Competence Card, participants must complete
              an NVQ Level 2 in Plant Operations within the two-year validity
              period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A18 Reach Truck Forklift course equips participants with
              essential skills and knowledge for safe and effective forklift
              operation. Tailored to industry standards, this training combines
              theoretical and practical learning to thoroughly prepare
              individuals for certification.
            </p>
            <p>
              Enroll today to achieve your CPCS certification and enhance your
              career opportunities in material handling and logistics.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
