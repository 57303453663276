import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSLoaderSecurer() {
  return (
    <>
      <Helmet>
        <title>A49 Loader Secure Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Loader Securer A49 training course at Alpha Construction Training to gain expertise in loading and securing mobile plant safely and effectively."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A49 Loader Securer Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is tailored for individuals seeking
              formal qualifications in loading and unloading mobile plant. It
              prepares participants to safely and efficiently handle loading and
              securing tasks, adhering to industry regulations and standards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the role and responsibilities of a loader/securer in
                the industry.
              </li>
              <li>
                Identify types of transporters, their purposes, and principal
                components.
              </li>
              <li>
                Conform to manufacturer's requirements and relevant legislation.
              </li>
              <li>Conduct pre-use checks on transporters and prime movers.</li>
              <li>
                Set up for travel on sites and public highways, both loaded and
                unloaded.
              </li>
              <li>
                Prepare the area and equipment for loading and unloading duties.
              </li>
              <li>
                Securely load and position various plant items on and off a
                trailer.
              </li>
              <li>
                Ensure compliance with communication procedures during
                operations.
              </li>
              <li>
                Explain considerations and legislative requirements for
                transporting loads on public highways.
              </li>
              <li>
                Plan and execute safe routes and loading procedures on public
                highways.
              </li>
              <li>Shut down and secure equipment post-operation.</li>
              <li>
                Understand the Road Traffic Act and its implications for plant
                operations.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course involves a mix of classroom instruction and practical
              exercises, culminating in a one-day assessment through CPCS
              technical tests to evaluate skills and knowledge.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive a Red CPCS Trained Operator
              Card, valid for two years. To upgrade to the Blue CPCS Competence
              Card, participants must complete an NVQ Level 2 in Plant
              Operations within the two-year timeframe. The Blue Card is
              renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Loader Securer A49 course is ideal for individuals aiming
              to gain certification and skills in loading and securing mobile
              plant. It provides comprehensive training on safe practices,
              legislative compliance, and operational efficiency.
            </p>
            <p>
              By completing this course, participants enhance their
              employability and meet industry requirements, paving the way for
              career advancement in the construction sector.
            </p>
            <p>
              Enroll today and take the first step toward becoming a certified
              loader/securer.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
