import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function RoadSweeper() {
  return (
    <>
      <Helmet>
        <title>N217 Road Sweeper Training Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Comprehensive N217 Road Sweeper Training Course by NPORS, covering operation, safety, and industry standards. Obtain your NPORS or NPORS CSCS card today!"
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N217 Road Sweeper Training Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed for individuals seeking to learn or
              enhance their skills in operating road sweepers safely and
              efficiently. It covers both theoretical and practical aspects of
              the operation, providing candidates with the knowledge and skills
              to achieve NPORS certification.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Introduction to the road sweeper, including controls, required
                PPE, and safe mounting/dismounting practices.
              </li>
              <li>
                Pre-start and running checks, with an explanation of major
                components, their features, benefits, and associated risks.
              </li>
              <li>
                Operator responsibilities, referencing the manufacturer’s
                handbook for the specific machine in use.
              </li>
              <li>
                Operating procedures in line with industry best practices and
                NPORS guidelines.
              </li>
              <li>Safe parking and shutdown procedures.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <ul>
              <li>
                <strong>Theory Assessment:</strong> A multiple-choice theory
                test consisting of 25 questions.
              </li>
              <li>
                <strong>Practical Test:</strong> Evaluates the candidate's
                ability to safely and effectively operate the road sweeper.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course, candidates will be
              issued an NPORS card. The type of card depends on site
              requirements, prior qualifications, and the employer’s CITB levy
              registration status. Available options include:
            </p>
            <ul>
              <li>
                <strong>NPORS Traditional Card:</strong> Valid for 3-5 years,
                this internationally recognised card is suited for general use
                but is less accepted in the construction industry compared to
                the CSCS embossed card.
              </li>
              <li>
                <strong>NPORS CSCS Red Trained Operator Card:</strong> Valid for
                two years, this card is issued after training/testing and
                requires a valid HS&E test.
              </li>
              <li>
                <strong>NPORS CSCS Blue Competent Operator Card:</strong> Valid
                for five years, this card is issued to operators who complete
                NPORS training/testing and hold a relevant NVQ.
              </li>
            </ul>
            <p>
              Note: Candidates must pass the CITB Health, Safety & Environment
              (HS&E) test within two years prior to applying for any NPORS CSCS
              card.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The N217 Road Sweeper Training Course is designed for all levels
              of experience. The course provides a comprehensive understanding
              of road sweeper operation and focuses on safety, efficiency, and
              adherence to industry standards.
            </p>
            <p>
              Candidates will gain theoretical and practical knowledge required
              for NPORS certification, helping them meet industry expectations
              and advance their careers.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
