import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSCompactCrane() {
  return (
    <>
      <Helmet>
        <title>A66 Compact Crane Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Compact Crane Training course to learn how to operate compact cranes safely and productively with essential skills and certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A66 Compact Crane Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is designed for individuals seeking a
              formal qualification in operating compact cranes. It covers all
              essential aspects of crane operation, from pre-use checks to
              safety procedures, to ensure safe and productive operation.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>Pre-use checks and preparation for work.</li>
              <li>Operate the crane safely and productively.</li>
              <li>
                Respond to recognised signals for controlling crane operations.
              </li>
              <li>Determine whether a load is within the crane's capacity.</li>
              <li>Perform minor adjustments and routine maintenance.</li>
              <li>
                Understand the crane's capabilities and relevant safety
                precautions.
              </li>
              <li>
                Operate safe-load indicators and overload warning devices.
              </li>
              <li>
                Understand how working radius affects the lifting capacity.
              </li>
              <li>
                Recognise the principles of safe slinging, types and capacities
                of lifting gear, security, and load balance.
              </li>
              <li>
                Observe statutory regulations regarding lifting equipment and
                operator responsibilities.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a mixture of classroom-based teaching and
              practical exercises, followed by one day of assessment - CPCS
              technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Learners who pass the CPCS technical tests will receive the Red
              CPCS Trained Operator Card, valid for two years. After gaining
              on-site experience, you must complete an NVQ Level 2 in Plant
              Operations - Preparing and operating compact cranes within two
              years to apply for the Blue CPCS Competence Card, which is
              renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Compact Crane Course equips participants with the
              essential skills to operate compact cranes safely and effectively.
              With an industry-recognized certification, this course is vital
              for anyone looking to advance in crane operation.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
