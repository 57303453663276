import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSConcretePlacingBoom() {
  return (
    <>
      <Helmet>
        <title>
          A72 Static Concrete Placing Boom Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join our CPCS Static Concrete Placing Boom A72 Training Course to gain essential skills in operating and maintaining static concrete placing booms for construction projects."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A72 Static Concrete Placing Boom Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              In the rapidly evolving construction industry, mastering the
              latest equipment is crucial for ensuring project efficiency and
              safety. Our Static Concrete Placing Boom A72 Course is
              meticulously designed to equip individuals with the essential
              skills and knowledge required to operate a Static Concrete Placing
              Boom proficiently. This course adheres to the highest industry
              standards and provides a robust understanding of the operational
              and safety procedures associated with static concrete placing
              booms.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the mechanics and operational controls of a Static
                Concrete Placing Boom.
              </li>
              <li>
                Safety protocols and emergency procedures for using static
                concrete placing booms.
              </li>
              <li>Routine maintenance and troubleshooting procedures.</li>
              <li>
                Practical assessments to gauge competency in real-world
                scenarios.
              </li>
              <li>
                Transport, set up, configuration, and safe operation of the
                equipment.
              </li>
              <li>Arranging, anchoring, and securing pipes and lines.</li>
              <li>Resolving blockages safely and effectively.</li>
              <li>
                Cleaning down procedures and completing relevant documentation.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes both theoretical and practical assessments to
              ensure you acquire the necessary knowledge and hands-on experience
              to operate the equipment safely and efficiently.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course, you will receive a
              recognised certification, validating your proficiency in operating
              a Static Concrete Placing Boom. This certification is highly
              valued in the construction sector and enhances your employability.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Static Concrete Placing Boom A72 Course is an exceptional
              opportunity to gain the necessary skills to become a competent
              operator. With a blend of theoretical knowledge and practical
              training, you’ll be equipped to handle static concrete placing
              booms safely and effectively, ensuring your success in the
              construction industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
