import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSRoughTerrainForklift() {
  return (
    <>
      <Helmet>
        <title>
          A14 Rough Terrain Forklift Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Learn to operate Rough Terrain Forklifts safely and efficiently with the A14 CPCS Rough Terrain Forklift training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A14 Rough Terrain Forklift Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is aimed at people who require a formal
              qualification for operating a Rough Terrain Forklift. Training
              duration will be determined based on a learner analysis, with
              options for both beginners and experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Risks associated with quick hitch devices and how to use them
                safely.
              </li>
              <li>Operating the machine to a minimum standard.</li>
              <li>Fitting and operating ancillary equipment.</li>
              <li>Siting the machine safely and efficiently.</li>
              <li>
                Carrying out minor adjustments and routine servicing procedures.
              </li>
              <li>
                Preparing the machine for travelling on the public highway.
              </li>
              <li>
                Understanding the capabilities, purposes, and limitations of the
                machine.
              </li>
              <li>
                Safety precautions necessary for correct machine operation.
              </li>
              <li>
                How diesel engines work and the operation of various machine
                parts.
              </li>
              <li>
                Routine adjustments and servicing procedures and how to perform
                them.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course includes a mix of classroom-based teaching and
              practical exercises, followed by a one-day assessment with CPCS
              technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon passing the CPCS technical tests, learners will receive a Red
              CPCS Trained Operator Card, valid for two years. To upgrade to a
              Blue CPCS Competence Card, participants must complete an NVQ Level
              2 in Plant Operations within two years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A14 CPCS Rough Terrain Forklift course provides comprehensive
              training to ensure safe and efficient forklift operation. It
              includes theoretical and practical components to meet industry
              standards and prepare participants for assessment.
            </p>
            <p>
              Enroll today to earn your industry-recognized certification and
              advance your career in construction and plant operations.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
