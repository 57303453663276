import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSDragline() {
  return (
    <>
      <Helmet>
        <title>A05 Dragline Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enhance your operational skills with the CPCS Dragline A05 Training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A05 Dragline Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Dragline A05 Training Course aims to provide participants
              with essential skills and knowledge to operate dragline machinery
              safely and efficiently. This course is suitable for both beginners
              and experienced operators.
            </p>
            <p>
              By the end of this training, you will have a comprehensive
              understanding of dragline operations, safety standards, and
              maintenance practices.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the structure and functionality of dragline
                machinery.
              </li>
              <li>Learn operational safety measures and best practices.</li>
              <li>
                Perform routine maintenance and identify potential issues.
              </li>
              <li>Gain hands-on experience with dragline operations.</li>
              <li>
                Comprehend legal and environmental considerations in dragline
                use.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will be assessed through a combination of theoretical
              knowledge tests and practical evaluations. These assessments are
              designed to ensure competency in both operational and safety
              aspects of dragline machinery.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully completing the course, participants will receive
              the CPCS Red Trained Operator Card. This certification validates
              your proficiency and can be upgraded to a Blue Competence Card by
              completing an NVQ Level 2 in Plant Operations within two years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Dragline A05 Training Course equips participants with the
              knowledge and skills to operate dragline machinery effectively and
              responsibly. Combining theoretical instruction with practical
              application, this course prepares learners for both CPCS
              certification tests and real-world challenges.
            </p>
            <p>
              Join Alpha Construction Training today to advance your career in
              the construction industry. Enroll now and take the first step
              towards becoming a certified dragline operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
