import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTelescopicHandler() {
  return (
    <>
      <Helmet>
        <title>
          A17 Telescopic Handler Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Gain a formal qualification in operating telescopic forklifts with the CPCS Telescopic Forklift (Telehandler) training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A17 Telescopic Handler Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS Telescopic Forklift (Telehandler) training course is
              designed for individuals seeking a formal qualification to operate
              telescopic handlers efficiently and safely.
            </p>
            <p>
              The course accommodates both beginners and experienced operators,
              with tailored durations based on prior experience. An experienced
              worker course is available for those with existing knowledge and
              skills.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>Operate the machine effectively for all normal tasks.</li>
              <li>Determine whether a specific load is safe to lift.</li>
              <li>
                Perform routine servicing and maintenance as per the
                manufacturer’s guidelines.
              </li>
              <li>
                Gain a comprehensive understanding of the machine's
                functionality and maintenance requirements.
              </li>
              <li>
                Assess the effects of load size, weight, gradients, and ground
                conditions on machine stability.
              </li>
              <li>
                Learn the proper methods for loading, storing, and transporting
                materials.
              </li>
              <li>
                Understand the basic construction and handling of pallets.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes classroom-based teaching combined with
              practical exercises. Assessment is conducted on the final day and
              consists of CPCS technical tests to evaluate knowledge and
              competence.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the CPCS technical tests, participants
              will be awarded the CPCS Red Trained Operator Card, valid for two
              years. To obtain the Blue CPCS Competence Card, participants must
              complete an NVQ Level 2 in Plant Operations – Preparing and
              Operating Telescopic Handlers within two years. The Blue Card is
              renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Telescopic Forklift (Telehandler) training course equips
              learners with essential skills and knowledge to operate telescopic
              handlers proficiently. Through a blend of theoretical and
              practical learning, the course ensures participants are
              well-prepared for both the technical tests and on-site operations.
            </p>
            <p>
              With industry-recognized certification, learners can advance their
              careers in construction and material handling. Enroll today to
              achieve formal qualifications and elevate your operational skills.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
