import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPilingRigAttendant() {
  return (
    <>
      <Helmet>
        <title>
          A74 Piling Rig Attendant Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join our CPCS Piling Rig Attendant A74 Training Course to gain essential skills for working with piling rigs in the construction industry."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A74 Piling Rig Attendant Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Embarking on a career in the construction sector requires a blend
              of theoretical knowledge and practical skills. Our Piling Rig
              Attendant A74 Course prepares you with essential skills to excel
              in operating piling rigs. It meets the highest industry standards,
              ensuring you are well-equipped to handle modern construction site
              demands.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>Safe operation of piling rigs.</li>
              <li>
                Understanding of ground conditions and their impact on piling
                rig operations.
              </li>
              <li>
                Adherence to safety protocols to ensure a safe working
                environment.
              </li>
              <li>
                Practical experience in operating piling rigs under various
                conditions.
              </li>
              <li>Real-world scenarios to enhance your learning experience.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of classroom-based teaching,
              practical exercises, and assessments to test your knowledge and
              skills in operating piling rigs safely and effectively.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course, you will receive a
              recognised certification as a Piling Rig Attendant A74. This
              certification serves as a valuable addition to your professional
              portfolio and demonstrates your competence in the field.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Piling Rig Attendant A74 Course provides a comprehensive
              training program that blends theoretical knowledge with hands-on
              practical skills. Upon completion, you will be well-prepared for a
              successful career in the construction industry, with the skills
              and certification necessary to excel as a Piling Rig Attendant.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
