import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function SlingerSignaller() {
  return (
    <>
      <Helmet>
        <title>N402 Slinger Signaller Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about the NPORS N402 Slinger Signaller Training Course, including course objectives, learning outcomes, assessment methods, and certification options."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N402 Slinger Signaller Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed for individuals who want to gain entry
              into the lifting industry. It provides participants with the
              knowledge and skills required to perform the duties of a
              Slinger/Signaller safely and efficiently in line with industry
              standards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Basic understanding of the lifting industry, its hazards, and
                operator responsibilities.
              </li>
              <li>
                Conformance to manufacturers' requirements, technical data, and
                relevant regulations.
              </li>
              <li>
                Identification and explanation of lifting equipment and
                accessories, including their construction, uses, and
                applications.
              </li>
              <li>
                Interpretation of relevant documentation and extraction of key
                information.
              </li>
              <li>
                Execution of pre-use checks, identification of non-serviceable
                items, and procedures for out-of-service equipment.
              </li>
              <li>
                Calculation of load weight and identification of the center of
                gravity.
              </li>
              <li>
                Establishment of exclusion zones and response to emergencies.
              </li>
              <li>
                Proper use and maintenance of PPE for Slinger/Signaller tasks.
              </li>
              <li>
                Securing loads to hooks using appropriate accessories while
                ensuring balance and integrity.
              </li>
              <li>
                Directing and guiding load movements using effective
                communication methods.
              </li>
              <li>
                Environmental considerations and out-of-service procedures.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>Candidates will be assessed through:</p>
            <ul>
              <li>
                <strong>Theory Test:</strong> Open questions and multiple-choice
                format.
              </li>
              <li>
                <strong>Practical Test:</strong> Hands-on demonstration of
                skills and knowledge.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates can obtain the following certifications:
            </p>
            <ul>
              <li>
                <strong>Trained Operator Card (2 years):</strong> Issued to
                operators without an (S)NVQ. They must complete the requisite
                (S)NVQ during this period to upgrade their card.
              </li>
              <li>
                <strong>Competent Operator Card (5 years):</strong> Issued to
                operators holding the requisite (S)NVQ. This includes a CPD
                record/logbook for tracking professional development and
                operational hours.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS N402 Slinger Signaller Training Course equips candidates
              with the essential skills and knowledge for lifting operations. It
              is suitable for both novice and experienced operators, with
              duration adjusted based on experience level.
            </p>
          </div>
        </div>
        <div className="contact-us-section">
          <button
            className="contact-us-button"
            onClick={() => (window.location.href = "/contactus")}
          >
            Contact Us
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
