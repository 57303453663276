import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSDumpTruckArticulated() {
  return (
    <>
      <Helmet>
        <title>A56 Dump Truck Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enroll in our CPCS Dump Truck Training course to gain the skills and certification needed for operating dump trucks with articulated chassis."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A56 Dump Truck Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is designed for individuals seeking a
              formal qualification in operating a Dump Truck with an articulated
              chassis. It is suitable for both new learners and experienced
              operators aiming to enhance their skills.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Risks associated with quick hitch devices and how to use them
                safely.
              </li>
              <li>Operating the machine to a minimum standard.</li>
              <li>Fitting and operating ancillary equipment.</li>
              <li>Siting the machine safely and efficiently.</li>
              <li>
                Carrying out minor adjustments and routine servicing procedures.
              </li>
              <li>
                Preparing the machine for travelling on the public highway.
              </li>
              <li>
                Understanding the capabilities, purposes, and limitations of the
                machine.
              </li>
              <li>
                How diesel engines work and the operation of various machine
                parts.
              </li>
              <li>
                Routine adjustments and servicing procedures necessary for
                efficient operation.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of classroom-based instruction
              and practical exercises. Participants will be assessed through
              CPCS technical tests, including both theoretical and practical
              components.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive the CPCS Red Trained Operator
              Card, valid for two years. To upgrade to the Blue Competence Card,
              participants must complete an NVQ Level 2 in Plant Operations
              within the two-year period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Dump Truck Training Course provides a comprehensive blend
              of theoretical knowledge and practical skills, ensuring
              participants are fully prepared to operate dump trucks with
              articulated chassis. Join Alpha Construction Training and take a
              significant step toward advancing your career in the construction
              industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
