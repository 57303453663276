import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSRideOnRoller() {
  return (
    <>
      <Helmet>
        <title>A31 Ride-On Roller Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Obtain a formal qualification for operating ride-on rollers with the CPCS Ride-On Roller (A31) training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A31 Ride-On Roller Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS Ride-On Roller (A31) training course is designed for
              individuals seeking a formal qualification to operate ride-on
              rollers efficiently and safely.
            </p>
            <p>
              The course caters to both beginners and experienced operators,
              with tailored durations based on prior experience. An experienced
              worker course is available for those with existing knowledge and
              skills.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>Perform all pre-start, running, and shut-down checks.</li>
              <li>Set the roller for travel and compaction duties.</li>
              <li>Recognize and respond to on-site hazards effectively.</li>
              <li>
                Understand conditions that may cause instability of the roller.
              </li>
              <li>
                Learn safety procedures for working near unsupported edges.
              </li>
              <li>
                Perform loading and unloading procedures on/off a transporter.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of classroom-based teaching and
              practical exercises. Assessment is conducted on the final day and
              consists of CPCS technical tests to evaluate knowledge and
              competence.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the CPCS technical tests, participants
              will be awarded the CPCS Red Trained Operator Card, valid for two
              years. To obtain the Blue CPCS Competence Card, participants must
              complete an NVQ Level 2 in Plant Operations – Preparing and
              Operating Ride-On Rollers within two years. The Blue Card is
              renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Ride-On Roller (A31) training course equips learners with
              essential skills and knowledge to operate ride-on rollers
              proficiently. Through a blend of theoretical and practical
              learning, the course ensures participants are well-prepared for
              both the technical tests and on-site operations.
            </p>
            <p>
              With industry-recognized certification, learners can advance their
              careers in construction and material handling. Enroll today to
              achieve formal qualifications and elevate your operational skills.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
