import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function SafeWorkingAtHeight() {
  return (
    <>
      <Helmet>
        <title>N404 Safe Working at Height | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn safe working practices at height with our NPORS N404 Safe Working at Height course. Practical and theory-based training to ensure safety and compliance."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N404 Safe Working at Height</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The NPORS N404 Safe Working at Height course provides candidates
              with the practical and theoretical knowledge required to safely
              perform tasks at height, ensuring compliance with industry
              standards and legislative requirements.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understand the dangers of working in the industry and the
                responsibilities of an operator.
              </li>
              <li>
                Gain knowledge of the manufacturer’s handbook and relevant
                legislation.
              </li>
              <li>
                Locate and identify the major components of the machinery and
                explain their functions.
              </li>
              <li>
                Perform pre-operational checks in accordance with manufacturer’s
                and legislative requirements.
              </li>
              <li>
                Identify and maintain appropriate PPE for tasks involving
                petrol-driven cut-off saws.
              </li>
              <li>
                Select suitable types of abrasive wheels and battery/petrol
                cut-off saws for specific tasks.
              </li>
              <li>
                Conduct necessary safety checks at the worksite and establish
                safety zones.
              </li>
              <li>
                Prepare, operate, and manage machinery safely and efficiently.
              </li>
              <li>Understand environmental considerations for operations.</li>
              <li>
                Learn best practices for storing and transporting abrasive
                wheels and battery/petrol cut-off saws.
              </li>
              <li>
                Perform end-of-shift and shutdown procedures safely and
                correctly.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              To achieve the NPORS Operator Card, candidates must pass both the
              NPORS Theory Test and Practical Test.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, candidates will receive the NPORS
              Operator Card, valid for three years. This certification is
              recognised nationally and internationally across various industry
              sectors.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <ul>
              <li>
                <strong>Duration:</strong> 0.5 days.
              </li>
              <li>
                <strong>Course Ratios:</strong> 6 delegates per session, 1
                machine, 1 day for novices.
              </li>
              <li>
                <strong>Course Aims:</strong> To provide candidates with
                thorough practical and theoretical training in the safe
                operation of handheld abrasive wheel cut-off saws.
              </li>
            </ul>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
