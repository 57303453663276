import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function TelescopicHandler() {
  return (
    <>
      <Helmet>
        <title>N010 Telescopic Handler Training | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about the NPORS N010 Telescopic Handler Training Course, including course objectives, learning outcomes, assessment, and certification options."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N010 Telescopic Handler Training</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed for individuals who require a formal
              qualification to operate a telescopic handler safely and
              effectively.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Basic understanding of the industry, potential dangers, and the
                responsibilities of a plant operator.
              </li>
              <li>
                Working knowledge of the manufacturer’s handbook for the machine
                and other relevant sources of information.
              </li>
              <li>
                Identifying and understanding the major components of the
                machine, their functions, and operational controls (steering,
                driving, braking).
              </li>
              <li>
                Performing pre-operational checks in accordance with
                manufacturer’s and legislative requirements.
              </li>
              <li>
                Safely mounting, dismounting, starting, and stopping the
                machine.
              </li>
              <li>
                Configuring the machine for travel and safely manoeuvring it
                under various conditions.
              </li>
              <li>
                Carrying out lifting and load handling tasks accurately and
                safely at different locations.
              </li>
              <li>
                Loading and unloading external transport safely and handling
                attachments.
              </li>
              <li>
                Demonstrating knowledge of transportation procedures for the
                machine.
              </li>
              <li>
                Performing end-of-shift and shut-down procedures following
                operational standards.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>To achieve certification, candidates must complete:</p>
            <ul>
              <li>
                NPORS Theory assessment comprising open questions and
                multiple-choice.
              </li>
              <li>NPORS Practical assessment.</li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive an NPORS card based on the
              following criteria:
            </p>
            <ul>
              <li>
                <strong>Existing NPORS Card Holder – No (S)NVQ Held:</strong>{" "}
                Trained Operator card valid for 2 years. Operators must register
                for and achieve the requisite (S)NVQ during this period.
              </li>
              <li>
                <strong>Existing NPORS Card Holder – (S)NVQ Held:</strong>{" "}
                Competent Operator card valid for 5 years. Operators will also
                receive a CPD record/logbook documenting their continuous
                professional development and operating hours.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This course provides the theoretical knowledge and practical
              skills necessary for operating a telescopic handler in a safe and
              efficient manner, adhering to industry standards and best
              practices.
            </p>
          </div>
        </div>
        <div className="contact-us-section">
          <button
            className="contact-us-button"
            onClick={() => (window.location.href = "/contactus")}
          >
            Contact Us
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
