import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTunnellingLocomotives() {
  return (
    <>
      <Helmet>
        <title>
          A67 Tunnelling Locomotives Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join our CPCS Tunnelling Locomotives (A67) Training Course to gain expertise in tunnel locomotive operations, safety protocols, and maintenance."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A67 Tunnelling Locomotives Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is meticulously designed to equip individuals with
              essential skills and knowledge for proficient operation of
              tunnelling locomotives. Through a combination of theoretical
              insights and practical training, participants will be prepared for
              roles in the tunnelling sector.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding safety protocols and operational procedures for
                tunnel locomotives.
              </li>
              <li>Basics of maintenance for tunnelling locomotives.</li>
              <li>
                Identifying hazards commonly encountered in tunnel environments.
              </li>
              <li>
                Understanding load types, track layouts, and signals, including
                traffic lights and hand signals.
              </li>
              <li>Emergency response procedures for unforeseen situations.</li>
              <li>Mastering manoeuvring techniques in confined spaces.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course spans two intensive days of training, combining
              classroom instruction and hands-on practical exercises. Learners
              will be assessed through CPCS technical tests to ensure
              proficiency and understanding of tunnelling locomotive operations.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the CPCS technical tests,
              participants will receive the CPCS A67 certification. This
              qualification opens doors to numerous career opportunities in the
              construction and tunnelling sectors, validating expertise in
              tunnel locomotive operations.
            </p>
          </div>

          <div className="entry-requirements">
            <h2>Entry Requirements</h2>
            <p>
              Learners must have passed the Operatives Health, Safety, and
              Environment Test within the last two years before the course start
              date. Additionally, a good understanding of spoken and written
              English is required to participate effectively.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
