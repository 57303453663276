import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Cpcs from "./pages/cpcs";
import Nvq from "./pages/nvq";
import Npors from "./pages/npors";
import AgriculturalTractor from "./pages/npors/agriculturaltractor";
import CableAvoidance from "./pages/npors/cableavoidance";
import ChainsawAssessment from "./pages/npors/chainsawoperation";
import Crusher from "./pages/npors/crusher";
import Dozer from "./pages/npors/dozer";
import Excavator180 from "./pages/npors/excavator180";
import Excavator360 from "./pages/npors/excavator360";
import ContactUs from "./pages/contactus";
import ExcavatorCrane from "./pages/npors/excavatorcrane";
import ExcavatorMicro from "./pages/npors/excavatormicro";
import ForwardTippingDumper from "./pages/npors/forwardtippingdumper";
import ForwardTippingMiniDumper from "./pages/npors/forwardtippingminidumper";
import GritterSnowplough from "./pages/npors/grittersnowplough";
import HarnessFallArrest from "./pages/npors/harnessandfallarrest";
import IndustrialForkliftCourse from "./pages/npors/industrialcounterbalancedforklift";
import LoadingShovelCourse from "./pages/npors/loadingshovel";
import LogHandlerCourse from "./pages/npors/loghandler";
import LorryLoaderCourse from "./pages/npors/lorryloader";
import MEWPBoomCourse from "./pages/npors/mewpboom";
import MEWPScissorCourse from "./pages/npors/mewpscissor";
import PaverCourse from "./pages/npors/paver";
import PlantLoaderSecurer from "./pages/npors/plantloadersecurer";
import PlantMachineryMarshal from "./pages/npors/plantmachinerymarshall";
import PlantMover from "./pages/npors/plantmover";
import RearTippingDumper from "./pages/npors/reartippingdumper";
import QuickHitchAwareness from "./pages/npors/quickhitchawareness";
import RoadPlaner from "./pages/npors/roadplaner";
import RideOnRoller from "./pages/npors/rideonroller";
import RoadSweeper from "./pages/npors/roadsweeper";
import SafeWorkingAtHeight from "./pages/npors/safeworkingatnight";
import SafetyAwareness from "./pages/npors/safetyawareness";
import Screener from "./pages/npors/screener";
import SlingerSignaller from "./pages/npors/slingersignaller";
import TelescopicHandler from "./pages/npors/telescopichandler";
import VehicleMarshal from "./pages/npors/vehiclemarshall";
import CPCSIndustrialForklift from "./pages/cpcs/cpcsindustrialforklift";
import CPCSAgriculturalTractor from "./pages/cpcs/cpcsagriculturaltractor";
import CPCSForwardTippingDumper from "./pages/cpcs/cpcsforwarddumper";
import CPCSCrawlerCrane from "./pages/cpcs/cpcscrawlercrane";
import CPCSLiftingOperations from "./pages/cpcs/cpcsliftingoperations";
import CPCSPlantDriving from "./pages/cpcs/cpcsplantdriving";
import CPCSTowerCrane from "./pages/cpcs/cpcstowercrane";
import CPCSPlantMarshaller from "./pages/cpcs/cpcsplantmarshaller";
import CPCSExcavator180Below5T from "./pages/cpcs/cpcsexcavator180below5t";
import CPCSExcavator180Above5T from "./pages/cpcs/cpcsexacvator180above5t";
import CPCSRoughTerrainForklift from "./pages/cpcs/cpcsroughterrainforklift";
import CPCSSideLoader from "./pages/cpcs/cpcssideloader";
import CPCSReachTruckForklift from "./pages/cpcs/cpcsreachtruck";
import CPCSGrader from "./pages/cpcs/cpcsgrader";
import CPCSHoist from "./pages/cpcs/cpcshoist";
import CPCSWheeledShovelCourse from "./pages/cpcs/cpcswheeledshovel";
import CPCSTrackedLoadingShovel from "./pages/cpcs/cpcstrackingloadingshovel";
import CPCSSkidSteerLoader from "./pages/cpcs/cpcsskidsteer";
import CPCSMotorisedScraper from "./pages/cpcs/cpcsmotorisedscraper";
import CPCSMewpScissor from "./pages/cpcs/cpcsmewpscissor";
import CPCSMewpMastClimber from "./pages/cpcs/cpcsmewpmastclimber";
import CPCSPilingRigTripod from "./pages/cpcs/cpcspilingrigtripod";
import CPCSCrawlerTractorDozer from "./pages/cpcs/cpcscrawlertractordozer";
import CPCSCrawlerSideBoom from "./pages/cpcs/cpcscrawlertractorsideboom";
import CPCSTrencher from "./pages/cpcs/cpcstrencher";
import CPCSSkipHandler from "./pages/cpcs/cpcsskiphandler";
import CPCSSlingerSignaller from "./pages/cpcs/cpcsslingersignaller";
import CPCSLoaderCompressor from "./pages/cpcs/cpcsloadercompressor";
import CPCSCrusher from "./pages/cpcs/cpcscrusher";
import CPCSScreener from "./pages/cpcs/cpcsscreener";
import CPCSTrailerMountedConcretePump from "./pages/cpcs/cpcstrailermountedpump";
import CPCSPilingRigDrivenBelow20Tonnes from "./pages/cpcs/cpcspilingribelow20t";
import CPCSPilingRigDrivenAbove20Tonnes from "./pages/cpcs/cpcspilingrigabove20t";
import CPCSPilingRigBoredBelow20Tonnes from "./pages/cpcs/cpcspilingrigboredbelow20t";
import CPCSLoaderSecurer from "./pages/cpcs/cpcsloadersecurer";
import CPCSTelescopicHandler from "./pages/cpcs/cpcstelescopichandler";
import CPCSRideOnRoller from "./pages/cpcs/cpcsrideonroller";
import CPCSMEWPBoom from "./pages/cpcs/cpcsmewpboom";
import CPCSDragline from "./pages/cpcs/cpcsdragline";
import CPCSDumpTruckArticulated from "./pages/cpcs/cpcsdumptruckarticulated";
import CPCSDumpTruckRigid from "./pages/cpcs/cpcsdumptruckrigid";
import CPCSExcavator360Below10T from "./pages/cpcs/cpcsexcavator360below10t";
import CPCSExcavator360Above10T from "./pages/cpcs/cpcsexcavator360above10t";
import CPCSMobileCrane from "./pages/cpcs/cpcsmobilecrane";
import CPCSCompactCrane from "./pages/cpcs/cpcscompactcrane";
import CPCSPilingRigAttendant from "./pages/cpcs/cpcspilingrigattendant";
import CPCSConcretePlacingBoom from "./pages/cpcs/cpcsconcreteplacingboom";
import CPCSDemolitionPlant from "./pages/cpcs/cpcsdemolitionplant";
import CPCSSkidSteerToolCarrier from "./pages/cpcs/cpcsskidsteertoolcarrier";
import CPCSPilingRigBoredAbove20Tonnes from "./pages/cpcs/cpcspilingrigboredabove20t";
import CPCSSoilLandfillCompactor from "./pages/cpcs/cpcssoillandfillcompactor";
import CPCSCraneSupervisor from "./pages/cpcs/cpcscranesupervisor";
import CPCSOverheadCrane from "./pages/cpcs/cpcsoverheadcrane";
import CPCSTunnellingLocomotives from "./pages/cpcs/cpcstunnellinglocomotives";
import CPCSSoilStabiliser from "./pages/cpcs/cpcssoilstabiliser";
import CPCSConveyorPump from "./pages/cpcs/cpcsconveyorpump";
import CPCSTelescopicHandler360Slew from "./pages/cpcs/cpcstelescopichandler360slew";
import CPCSVacuumExcavator from "./pages/cpcs/cpcsvacuumexcavator";
import CPCSDemolitionPlantPedestrianOperated from "./pages/cpcs/cpcsdemolitionplantpedestrian";
import MarineKnuckleBoomCrane from "./pages/npors/marineknuckleboom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cpcs" element={<Cpcs />} />
        <Route path="/npors" element={<Npors />} />
        <Route path="/nvq" element={<Nvq />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/agriculturaltractor" element={<AgriculturalTractor />} />
        <Route path="/cableavoidance" element={<CableAvoidance />} />
        <Route path="/chainsawoperation" element={<ChainsawAssessment />} />
        <Route path="/crusher" element={<Crusher />} />
        <Route path="/dozer" element={<Dozer />} />
        <Route path="/excavator180" element={<Excavator180 />} />
        <Route path="/excavator360" element={<Excavator360 />} />
        <Route path="/excavatorcrane" element={<ExcavatorCrane />} />
        <Route path="/excavatormicro" element={<ExcavatorMicro />} />
        <Route
          path="/forwardtippingdumper"
          element={<ForwardTippingDumper />}
        />
        <Route
          path="/forwardtippingminidumper"
          element={<ForwardTippingMiniDumper />}
        />
        <Route path="/grittersnowplough" element={<GritterSnowplough />} />
        <Route path="/harnessandfallarrest" element={<HarnessFallArrest />} />
        <Route
          path="/industrialcounterbalancedforklift"
          element={<IndustrialForkliftCourse />}
        />
        <Route path="/loadingshovel" element={<LoadingShovelCourse />} />
        <Route path="/loghandler" element={<LogHandlerCourse />} />
        <Route path="/lorryloader" element={<LorryLoaderCourse />} />
        <Route path="/mewpboom" element={<MEWPBoomCourse />} />
        <Route path="/mewpscissor" element={<MEWPScissorCourse />} />
        <Route path="/marineknuckleboom" element={<MarineKnuckleBoomCrane />} />
        <Route path="/paver" element={<PaverCourse />} />
        <Route path="/plantloadersecurer" element={<PlantLoaderSecurer />} />
        <Route path="/plantmachinery" element={<PlantMachineryMarshal />} />
        <Route path="/plantmover" element={<PlantMover />} />
        <Route path="/reartippingdumper" element={<RearTippingDumper />} />
        <Route path="/quickhitchawareness" element={<QuickHitchAwareness />} />
        <Route path="/roadplaner" element={<RoadPlaner />} />
        <Route path="/rideonroller" element={<RideOnRoller />} />
        <Route path="/roadsweeper" element={<RoadSweeper />} />
        <Route path="/safeworkingatnight" element={<SafeWorkingAtHeight />} />
        <Route path="/safetyawareness" element={<SafetyAwareness />} />
        <Route path="/screener" element={<Screener />} />
        <Route path="/slingersignaller" element={<SlingerSignaller />} />
        <Route path="/telescopichandler" element={<TelescopicHandler />} />
        <Route path="/vehiclemarshall" element={<VehicleMarshal />} />
        <Route path="/cpcs-crawlercrane" element={<CPCSCrawlerCrane />} />
        <Route
          path="/cpcs-industrialforklift"
          element={<CPCSIndustrialForklift />}
        />
        <Route
          path="/cpcs-agriculturaltractor"
          element={<CPCSAgriculturalTractor />}
        />
        <Route
          path="/cpcs-forwardtippingdumper"
          element={<CPCSForwardTippingDumper />}
        />
        <Route
          path="/cpcs-liftingoperations"
          element={<CPCSLiftingOperations />}
        />
        <Route path="/cpcs-plantdriving" element={<CPCSPlantDriving />} />
        <Route path="/cpcs-towercrane" element={<CPCSTowerCrane />} />
        <Route path="/cpcs-plantmarshaller" element={<CPCSPlantMarshaller />} />
        <Route
          path="/cpcs-excavatorbelow5t"
          element={<CPCSExcavator180Below5T />}
        />
        <Route
          path="/cpcs-excavatorabove5t"
          element={<CPCSExcavator180Above5T />}
        />
        <Route
          path="/cpcs-roughterrainforklift"
          element={<CPCSRoughTerrainForklift />}
        />
        <Route path="/cpcs-sideloader" element={<CPCSSideLoader />} />
        <Route path="/cpcs-reachtruck" element={<CPCSReachTruckForklift />} />
        <Route path="/cpcs-hoist" element={<CPCSHoist />} />
        <Route path="/cpcs-grader" element={<CPCSGrader />} />
        <Route
          path="/cpcs-wheeledshovel"
          element={<CPCSWheeledShovelCourse />}
        />
        <Route
          path="/cpcs-trackedloadingshovel"
          element={<CPCSTrackedLoadingShovel />}
        />
        <Route path="/cpcs-skidsteerloader" element={<CPCSSkidSteerLoader />} />
        <Route
          path="/cpcs-motorisedscraper"
          element={<CPCSMotorisedScraper />}
        />
        <Route path="/cpcs-mewpscissor" element={<CPCSMewpScissor />} />
        <Route path="/cpcs-mewpmastclimber" element={<CPCSMewpMastClimber />} />
        <Route path="/cpcs-pilingrigtripod" element={<CPCSPilingRigTripod />} />
        <Route
          path="/cpcs-crawlertractordozer"
          element={<CPCSCrawlerTractorDozer />}
        />
        <Route
          path="/cpcs-crawlertractorsideboom"
          element={<CPCSCrawlerSideBoom />}
        />

        <Route path="/cpcs-trencher" element={<CPCSTrencher />} />
        <Route path="/cpcs-skiphandler" element={<CPCSSkipHandler />} />
        <Route
          path="/cpcs-slingersignaller"
          element={<CPCSSlingerSignaller />}
        />
        <Route
          path="/cpcs-loadersignaller"
          element={<CPCSLoaderCompressor />}
        />
        <Route path="/cpcs-crusher" element={<CPCSCrusher />} />
        <Route path="/cpcs-screener" element={<CPCSScreener />} />
        <Route
          path="/cpcs-trailermountedpump"
          element={<CPCSTrailerMountedConcretePump />}
        />
        <Route
          path="/cpcs-pilingrigbelow20t"
          element={<CPCSPilingRigDrivenBelow20Tonnes />}
        />
        <Route
          path="/cpcs-pilingrigabove20t"
          element={<CPCSPilingRigDrivenAbove20Tonnes />}
        />
        <Route
          path="/cpcs-pilingrigboredbelow20t"
          element={<CPCSPilingRigBoredBelow20Tonnes />}
        />
        <Route path="/cpcs-loadersecurer" element={<CPCSLoaderSecurer />} />
        <Route
          path="/cpcs-telescopichandler"
          element={<CPCSTelescopicHandler />}
        />
        <Route path="/cpcs-rideonroller" element={<CPCSRideOnRoller />} />
        <Route path="/cpcs-mewpboom" element={<CPCSMEWPBoom />} />
        <Route path="/cpcs-dragline" element={<CPCSDragline />} />
        <Route
          path="/cpcs-dumptruckarticulated"
          element={<CPCSDumpTruckArticulated />}
        />
        <Route path="/cpcs-dumptruckrigid" element={<CPCSDumpTruckRigid />} />
        <Route
          path="/cpcs-excavator360below10t"
          element={<CPCSExcavator360Below10T />}
        />
        <Route
          path="/cpcs-excavator360above10t"
          element={<CPCSExcavator360Above10T />}
        />
        <Route path="/cpcs-mobilecrane" element={<CPCSMobileCrane />} />
        <Route path="/cpcs-comapctcrane" element={<CPCSCompactCrane />} />
        <Route
          path="/cpcs-pilingrigattendant"
          element={<CPCSPilingRigAttendant />}
        />
        <Route
          path="/cpcs-concreteplacingboom"
          element={<CPCSConcretePlacingBoom />}
        />
        <Route path="/cpcs-demolitionplant" element={<CPCSDemolitionPlant />} />
        <Route
          path="/cpcs-skidsteertoolcarrier"
          element={<CPCSSkidSteerToolCarrier />}
        />
        <Route
          path="/cpcs-pilingrigboredabove20t"
          element={<CPCSPilingRigBoredAbove20Tonnes />}
        />
        <Route
          path="/cpcs-soillandfillcompactor"
          element={<CPCSSoilLandfillCompactor />}
        />
        <Route path="/cpcs-cranesupervisor" element={<CPCSCraneSupervisor />} />
        <Route path="/cpcs-overheadcrane" element={<CPCSOverheadCrane />} />
        <Route
          path="/cpcs-tunellinglocomotives"
          element={<CPCSTunnellingLocomotives />}
        />
        <Route path="/cpcs-soilstabiliser" element={<CPCSSoilStabiliser />} />
        <Route path="/cpcs-conveyorpump" element={<CPCSConveyorPump />} />
        <Route
          path="/cpcs-telescopichandler360slew"
          element={<CPCSTelescopicHandler360Slew />}
        />
        <Route path="/cpcs-vacuumexcavator" element={<CPCSVacuumExcavator />} />
        <Route
          path="/cpcs-demolitionplantpedestrianoperated"
          element={<CPCSDemolitionPlantPedestrianOperated />}
        />
      </Routes>
    </Router>
  );
}

export default App;
