import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSDemolitionPlantPedestrianOperated() {
  return (
    <>
      <Helmet>
        <title>
          D91 Pedestrian Operated Demolition Plant Course | Alpha Construction
          Training
        </title>
        <meta
          name="description"
          content="Enroll in our CPCS Demolition Plant – Pedestrian Operated D91 Training Course to gain expertise in operating demolition equipment safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>D91 Pedestrian Operated Demolition Plant Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Our CPCS Demolition Plant – Pedestrian Operated D91 course is
              designed to equip participants with the theoretical knowledge and
              practical skills required for operating pedestrian-controlled
              demolition equipment. This course ensures safe operation and
              prepares you for real-world demolition challenges while meeting
              CPCS certification standards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding various types of pedestrian-operated demolition
                plants.
              </li>
              <li>
                Adhering to safety protocols and implementing hazard prevention
                measures.
              </li>
              <li>
                Mastering the operation and maintenance of demolition equipment.
              </li>
              <li>
                Working safely in confined spaces and with abrasive wheels.
              </li>
              <li>
                Managing tasks involving underground and overhead services.
              </li>
              <li>Maintaining organized and tidy working areas.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course includes a combination of classroom instruction and
              hands-on training, followed by a CPCS technical test to evaluate
              your competence in operating pedestrian-operated demolition
              plants.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Participants who successfully complete the course will receive the
              CPCS Red Trained Operator Card for Demolition Plant – Pedestrian
              Operated (D91), valid for two years. To upgrade to the CPCS Blue
              Competence Card, participants must complete an NVQ Level 2
              qualification within two years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Demolition Plant – Pedestrian Operated D91 Training
              Course is an essential program for those looking to enhance their
              skills in demolition operations. Spanning multiple days, this
              competitively priced course provides theoretical knowledge and
              practical expertise, ensuring participants are fully prepared for
              industry demands. A recognized CPCS certification is awarded upon
              completion, paving the way for numerous career opportunities in
              the construction and demolition sectors.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
