import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSDumpTruckRigid() {
  return (
    <>
      <Helmet>
        <title>A57 Dump Truck Rigid Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enroll in our CPCS Dump Truck – Rigid Chassis Training course to gain essential skills and certification for operating rigid chassis dump trucks."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A57 Dump Truck Rigid Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is designed to equip participants with
              the skills and knowledge required to operate rigid chassis dump
              trucks safely and efficiently. It caters to both novices and
              experienced operators aiming to gain or refresh their
              qualifications.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Preparation and safe operation of rigid chassis dump trucks.
              </li>
              <li>
                Understanding risks associated with dump truck operation and
                adhering to safety protocols.
              </li>
              <li>
                Efficient site positioning and machine handling techniques.
              </li>
              <li>Fitting and using ancillary equipment.</li>
              <li>Executing routine servicing and minor adjustments.</li>
              <li>Preparing the machine for travel on public highways.</li>
              <li>
                Comprehensive understanding of the machine’s capabilities,
                purposes, and limitations.
              </li>
              <li>Maintaining clean and safe working environments on-site.</li>
              <li>
                Recognizing and addressing hazards in diverse operational
                contexts.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undergo a mix of classroom-based instruction and
              practical exercises. The course concludes with a CPCS technical
              test that assesses both theoretical knowledge and practical
              skills.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the CPCS technical tests, participants
              will receive the CPCS Red Trained Operator Card, valid for two
              years. Progressing to the Blue Competence Card requires completing
              an NVQ Level 2 in Plant Operations within this period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Dump Truck – Rigid Chassis Training Course is an
              excellent opportunity to develop critical skills and gain
              industry-recognized certification. Join Alpha Construction
              Training to advance your expertise and career in the construction
              sector.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
