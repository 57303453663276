import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function SafetyAwareness() {
  return (
    <>
      <Helmet>
        <title>S001 Safety Awareness Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about the NPORS Safety Awareness (S001) course, covering key health and safety principles for construction site operatives, including Labourers Green Card eligibility."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>S001 Safety Awareness Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The NPORS Construction Safety Awareness Course has been mapped as
              a full equivalent to the QCF Level 1 Award in Health and Safety in
              a Construction Environment. This course not only benefits plant
              operators but also enables labourers to apply for the CSCS Green
              Labourers Card upon passing the CITB Health, Safety, and
              Environment Operatives Test.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>The importance of accident prevention on-site.</li>
              <li>Basic understanding of health and safety laws.</li>
              <li>
                How your role fits into the control and management of the site.
              </li>
              <li>Risk assessments and the purpose of method statements.</li>
              <li>
                Performing tasks safely and knowing when to ask for advice.
              </li>
              <li>
                Reporting unsafe acts to help prevent potential accidents.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes an NPORS Theory Assessment consisting of open
              questions and multiple-choice tests to evaluate your understanding
              of the course material.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Labourers who successfully complete the NPORS Site Safety
              Awareness Course can apply for the CSCS Green Labourers Card,
              provided they also pass the CITB Health, Safety, and Environment
              Operatives Test. This ensures they meet the basic health and
              safety competency standards for construction site work.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <ul>
              <li>
                <strong>Audience:</strong> Plant operators and labourers aiming
                to enhance safety awareness and secure CSCS Green Labourers
                Cards.
              </li>
              <li>
                <strong>Recognised By:</strong> Endorsed by Build UK as standard
                training for all site operatives.
              </li>
              <li>
                <strong>Industry Standards:</strong> Updated to reflect current
                legislation and the United Kingdom Contractors Group Health &
                Safety Training Standard.
              </li>
            </ul>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
