import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSDemolitionPlant() {
  return (
    <>
      <Helmet>
        <title>D90 Demolition Plant Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Demolition Plant D90 Training Course to gain essential skills in operating demolition plant machinery safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>Demolition Plant D90 Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Embarking on a career in the demolition sector requires a blend of
              theoretical knowledge and practical skills. Our CPCS Demolition
              Plant D90 Course is carefully designed to provide you with
              essential skills and certifications. This course ensures you
              understand the safety protocols and operational techniques
              required for demolition plant machinery.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding safety regulations and best practices in
                demolition.
              </li>
              <li>
                Operational techniques and machinery handling for demolition
                plant equipment.
              </li>
              <li>
                Maintenance and troubleshooting of demolition plant machinery.
              </li>
              <li>
                Practical, hands-on training to build confidence and competence.
              </li>
              <li>
                Understanding tools used in demolition, including hand,
                pneumatic, and cutting tools.
              </li>
              <li>
                Safety measures when working at height, with abrasive wheels,
                and in confined spaces.
              </li>
              <li>
                Managing hazards related to underground and overhead services
                and maintaining safe work areas.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes both theoretical and practical assessments to
              ensure that you acquire the necessary knowledge and hands-on
              experience to operate demolition plant machinery safely and
              efficiently.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course, you will receive a CPCS
              accredited certificate, recognized across the construction and
              demolition industry. This certification will significantly enhance
              your employability and career prospects.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Demolition Plant D90 Course provides comprehensive
              training for operating demolition plant machinery. With an
              emphasis on safety, operational techniques, and maintenance, this
              course is an essential stepping stone for a successful career in
              demolition.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
