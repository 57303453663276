import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function LorryLoaderCourse() {
  return (
    <>
      <Helmet>
        <title>N107 Lorry Loader Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Gain a formal qualification for operating a Lorry Loader with NPORS-certified N107 Lorry Loader training at Alpha Constructions. Develop essential skills and earn your certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N107 Lorry Loader Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to provide individuals with the necessary
              skills and qualifications to safely and effectively operate a
              Lorry Loader. Whether you are new to the industry or an
              experienced operator seeking certification, this course caters to
              all levels.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understanding the industry, associated hazards, and operator
                responsibilities.
              </li>
              <li>
                Familiarity with the manufacturer's handbook, applicable
                regulations, and legislation.
              </li>
              <li>
                Identifying and understanding the functions of major components
                of the lorry and loader crane.
              </li>
              <li>
                Locating and explaining the purpose of operating controls.
              </li>
              <li>
                Conducting pre-operational checks as per manufacturer's and
                legislative requirements.
              </li>
              <li>
                Preparing the lorry loader for travel and maneuvering safely
                across various terrains and confined spaces.
              </li>
              <li>
                Performing safety checks at the worksite and configuring the
                loader for lifting operations.
              </li>
              <li>
                Correctly deploying stabilizers and adhering to manufacturer
                specifications.
              </li>
              <li>
                Understanding load weight and center of gravity calculations.
              </li>
              <li>
                Performing lifting operations utilizing the full capabilities of
                the loader crane.
              </li>
              <li>
                Accurately positioning loads at designated locations, including
                loading and unloading the vehicle.
              </li>
              <li>
                Using and maintaining attachments and lifting accessories as
                required.
              </li>
              <li>Minimizing load swing and securing loads on the vehicle.</li>
              <li>
                Following end-of-shift procedures and shutting down the machine
                safely.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a theory assessment featuring open and
              multiple-choice questions, as well as a practical evaluation to
              assess the participant's competency in operating a lorry loader
              safely and efficiently.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Participants who complete the course successfully will receive an
              NPORS ID card.
            </p>
            <ul>
              <li>
                **Trained Operator Card**: Valid for 2 years, issued to
                operators without the requisite (S)NVQ. Operators are required
                to achieve the (S)NVQ within this period to maintain
                certification.
              </li>
              <li>
                **Competent Operator Card**: Valid for 5 years, awarded to
                operators holding the necessary (S)NVQ, which also includes a
                CPD logbook to track professional development and operating
                hours.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS N107 Lorry Loader Course equips participants with the
              skills and qualifications needed to operate a lorry loader safely
              and efficiently. It is suitable for operators of all experience
              levels, offering flexible training durations based on individual
              expertise. This comprehensive training ensures participants meet
              industry standards and requirements.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
