import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSMobileCrane() {
  return (
    <>
      <Helmet>
        <title>A60 Mobile Crane Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Mobile Crane Training course to learn how to operate mobile cranes safely and productively with essential skills and certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A60 Mobile Crane Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is for people with limited or no
              experience of operating mobile cranes. It teaches you how to
              operate a mobile crane safely and productively.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>Operate mobile cranes safely and productively.</li>
              <li>Respond to recognised crane signals accurately.</li>
              <li>Position the crane correctly.</li>
              <li>Alter jib lengths and associated safety devices.</li>
              <li>Carry out minor adjustments and routine maintenance.</li>
              <li>Understand duties charts.</li>
              <li>Programme safe-load indicators.</li>
              <li>
                Understand the basic theory of power units, transmission
                systems, and control systems.
              </li>
              <li>
                Understand the effects of radius change on the safe working
                capacity.
              </li>
              <li>Observe statutory regulations and Codes of Practice.</li>
              <li>
                Gauge the effect of shock loading on structural strength,
                stability, and load displacement.
              </li>
              <li>
                Recognise the principles of safe slinging, the types and
                capacities of chains, slings, and lifting gear.
              </li>
              <li>Understand the safe use of outriggers.</li>
              <li>
                Be aware of transport regulations and how to prepare to go on
                the public highway.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes six days of learning through a mix of
              classroom-based teaching and practical exercises, followed by one
              day assessment - CPCS technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Learners will receive a Red CPCS Trained Operator Card on passing
              the CPCS technical tests, valid for two years. After gaining
              on-site experience, you must complete an NVQ Level 2 in Plant
              Operations - Preparing and operating mobile cranes within two
              years to apply for the blue CPCS Competence Card, which is
              renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Mobile Crane Training Course provides participants with
              the skills and knowledge to operate mobile cranes safely and
              effectively. With industry-recognized certification, this course
              is an essential step in your career in crane operation.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
