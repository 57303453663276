import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSAgriculturalTractor() {
  return (
    <>
      <Helmet>
        <title>
          A33 CPCS Agricultural Tractor Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Learn to operate agricultural tractors proficiently with the CPCS A33 Agricultural Tractor course by Alpha Construction Training. Gain industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A33 CPCS Agricultural Tractor Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is meticulously designed to equip participants with
              the knowledge and skills needed to operate agricultural tractors
              proficiently. It ensures adherence to CPCS standards while
              preparing attendees for real-world operations in the agricultural
              sector.
            </p>
            <p>
              Whether you're a novice or an experienced operator, this course
              will help you gain a formal qualification, elevate your
              professional skills, and open up new career opportunities.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the purpose and function of agricultural tractors and
                their key components.
              </li>
              <li>
                Perform pre-operational checks and maintenance to ensure the
                safe and efficient use of the tractor.
              </li>
              <li>
                Master safe operational techniques, including maneuvering in
                confined spaces and with attachments.
              </li>
              <li>
                Learn how to configure and prepare tractors for road transport.
              </li>
              <li>
                Understand essential safety protocols, including hazard
                management and actions required for underground and overhead
                services.
              </li>
              <li>
                Carry out tasks such as hauling trailers, maintaining safe and
                tidy working areas, and securing tractors for transport or
                storage.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course involves a combination of theoretical learning and
              hands-on practical exercises. At the end of the training, a
              rigorous assessment is conducted, including the CPCS technical
              test, to evaluate your competence and knowledge.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants receive the CPCS Red
              Trained Operator Card, valid for two years. To progress further,
              you can complete an NVQ Level 2 qualification within the two-year
              period, enabling you to obtain the CPCS Blue Competence Card,
              renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This CPCS Agricultural Tractor (A33) Course is tailored to provide
              participants with a thorough understanding of agricultural tractor
              operation and maintenance. Combining theoretical knowledge with
              practical applications, it prepares you for a successful career in
              the agricultural sector.
            </p>
            <p>
              With industry-recognized certification, this course ensures you
              gain the necessary expertise to operate agricultural tractors
              safely and effectively. Enroll now and take the first step toward
              mastering tractor operations.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
