import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function PlantMover() {
  return (
    <>
      <Helmet>
        <title>N132 Plant Mover Course | Alpha Constructions</title>
        <meta
          name="description"
          content="NPORS Plant Mover training for operators handling machinery in non-operational roles. Covers safety, machinery movement, and loading procedures."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N132 Plant Mover Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is tailored for operators involved in the safe
              movement of plant machinery for non-operational duties, such as
              mechanics and delivery drivers. Participants will gain knowledge
              and skills to safely handle machinery for maintenance, repair, and
              transportation.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understand the industry, associated dangers, and operator
                responsibilities.
              </li>
              <li>
                Familiarize with the manufacturer’s handbook for machinery use.
              </li>
              <li>
                Locate and identify major machine components and controls, and
                explain their functions.
              </li>
              <li>
                Perform pre-operational checks per manufacturer and legislative
                standards.
              </li>
              <li>
                Identify and use appropriate PPE for plant mover operations.
              </li>
              <li>Prepare and operate machinery safely and efficiently.</li>
              <li>
                Conduct safety checks at the work area and consider
                environmental factors.
              </li>
              <li>
                Learn procedures for storing and transporting machinery
                attachments.
              </li>
              <li>
                Understand safe loading and unloading processes for machine
                transportation.
              </li>
              <li>Execute end-of-shift shutdown and parking procedures.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>Participants will undergo:</p>
            <ul>
              <li>
                NPORS Theory Assessment: Open and multiple-choice questions.
              </li>
              <li>
                NPORS Practical Assessment: Hands-on evaluation of skills.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful participants will receive one of the following NPORS
              cards:
            </p>
            <ul>
              <li>
                Trained Operator Card (RED): Valid for 2 years for operators
                without an NVQ. NVQ completion within this timeframe is required
                to upgrade.
              </li>
              <li>
                Competent Operator Card (BLUE): Valid for 5 years for operators
                with the requisite NVQ, along with a CPD logbook for ongoing
                professional development.
              </li>
            </ul>
            <p>
              All applicants must pass the CITB Health, Safety & Environmental
              test within 2 years prior to applying for certification.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS Plant Mover course provides essential training for
              operators who handle machinery in non-operational roles. This
              includes plant mechanics, delivery drivers, and other personnel
              responsible for machinery movement. The course emphasizes safety,
              maintenance, and efficient machinery handling.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
