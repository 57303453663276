import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSVacuumExcavator() {
  return (
    <>
      <Helmet>
        <title>A78 Vacuum Excavator Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enroll in our CPCS Vacuum Excavator A78 Training Course to gain expertise in operating vacuum excavators safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A78 Vacuum Excavator Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Our CPCS Vacuum Excavator A78 training course is meticulously
              designed to provide participants with the theoretical knowledge
              and practical skills required to operate vacuum excavators
              proficiently. This course prepares you for safe and efficient
              excavation operations while meeting CPCS certification standards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the functionality and operation of vacuum
                excavators.
              </li>
              <li>
                Adhering to safety protocols and conducting risk assessments.
              </li>
              <li>Efficiently handling and maintaining the equipment.</li>
              <li>Operating vacuum excavators in a controlled environment.</li>
              <li>
                Transporting, setting up, configuring, and maneuvering equipment
                in confined spaces.
              </li>
              <li>
                Controlling pipes, lines, and attachments for effective
                operation.
              </li>
              <li>
                Resolving blockages safely and performing clean-down procedures.
              </li>
              <li>Completing relevant documentation accurately.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course includes a mixture of classroom-based teaching and
              practical exercises, culminating in a CPCS technical test to
              assess your competency in operating a vacuum excavator.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will receive the CPCS Red
              Trained Operator Card for Vacuum Excavator (A78), valid for two
              years. To upgrade to the CPCS Blue Competence Card, participants
              must complete an NVQ Level 2 qualification within two years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The Vacuum Excavator A78 Training Course is a comprehensive
              program that combines theoretical and practical learning to
              prepare participants for proficient operation of vacuum
              excavators. The course spans two days and is competitively priced,
              offering excellent value for money. Participants will gain a
              recognized CPCS certification, opening up career opportunities in
              the construction industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
