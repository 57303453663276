import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSoilStabiliser() {
  return (
    <>
      <Helmet>
        <title>A71 Soil Stabiliser Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Soil Stabiliser (A71) Training Course to master soil stabilisation techniques, machinery operation, and safety protocols."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A71 Soil Stabiliser Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Embark on a journey to master soil stabilisation techniques with
              our comprehensive course. This program is meticulously crafted to
              equip participants with the theoretical knowledge and practical
              skills necessary for proficient operation of soil stabilisers.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the principles and applications of soil
                stabilisation.
              </li>
              <li>Operation and maintenance of soil stabilising machinery.</li>
              <li>Identifying different soil types and their properties.</li>
              <li>Adhering to safety and environmental protocols.</li>
              <li>
                Handling and troubleshooting machinery for optimal performance.
              </li>
              <li>
                Preparing and manoeuvring soil stabilisers in various terrains
                and conditions.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes intensive training sessions, blending
              classroom instruction with hands-on practical exercises.
              Participants will be assessed through CPCS technical tests to
              ensure competency in operating soil stabilisers effectively.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful participants will receive the CPCS A71 certification, a
              widely recognized qualification validating expertise in soil
              stabilisation. This certification significantly enhances
              employability in the construction industry.
            </p>
          </div>

          <div className="entry-requirements">
            <h2>Entry Requirements</h2>
            <p>
              Participants must have passed the Operatives Health, Safety, and
              Environment Test within the last two years. Proficiency in spoken
              and written English is essential to ensure effective
              participation.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
