import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSOverheadCrane() {
  return (
    <>
      <Helmet>
        <title>
          A64 Overhead Travelling Crane Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join our CPCS Overhead Travelling Crane (A64) Training Course to learn safe operation and maintenance of overhead cranes, ensuring compliance with industry standards."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A64 Overhead Travelling Crane Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed for individuals with limited or no
              experience operating an Overhead Travelling Crane. It aims to
              teach the safe operation and maintenance of overhead cranes in
              compliance with industry standards and best practices.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Recognizing the duties of personnel involved in lifting
                operations.
              </li>
              <li>
                Crane appreciation: types, applications, limitations, and
                terminology.
              </li>
              <li>Documentation for lifting equipment and accessories.</li>
              <li>
                Performing load calculations and determining the center of
                gravity.
              </li>
              <li>
                Conducting risk assessments and preparing method statements.
              </li>
              <li>
                Understanding safe working loads (SWL), working load limits, and
                mode factors of lifting accessories.
              </li>
              <li>
                Identifying different types of lifting accessories and their
                applications.
              </li>
              <li>
                Mastering communication procedures and signaling techniques.
              </li>
              <li>
                Basics of power units, control systems, and safety devices.
              </li>
              <li>Performing crane daily inspections and fault reporting.</li>
              <li>
                Operating the crane in various planes as per manufacturers’
                recommendations.
              </li>
              <li>Controlling load movement with different load types.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course involves two days of learning through a mix of
              classroom-based teaching and practical exercises, followed by a
              one-day assessment through CPCS technical tests to evaluate your
              skills and knowledge.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the CPCS technical tests, learners will
              receive a Red CPCS Trained Operator Card, valid for two years. To
              upgrade to the Blue CPCS Competence Card, learners must complete
              an NVQ Level 2 in Plant Operations - Preparing and Operating
              Overhead Cranes within two years. The Blue Card is renewable every
              five years.
            </p>
          </div>

          <div className="entry-requirements">
            <h2>Entry Requirements</h2>
            <p>
              Learners must have passed the Operatives Health, Safety, and
              Environment Test within the last two years before the course start
              date. A good understanding of spoken and written English is also
              required.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
