import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPilingRigBoredBelow20Tonnes() {
  return (
    <>
      <Helmet>
        <title>
          A47 Piling Rig Bored Below 20 Tonnes Course | Alpha Construction
          Training
        </title>
        <meta
          name="description"
          content="Enroll in the CPCS Piling Rig Bored Below 20 Tonnes A47 training course at Alpha Construction Training to gain essential skills for operating piling rigs safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A47 Piling Rig Bored Below 20 Tonnes Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The Piling Rig Bored Below 20 Tonnes A47 CPCS training course is
              designed to provide participants with the knowledge and practical
              skills required to operate piling rigs safely and efficiently.
              This course ensures compliance with industry standards and
              prepares participants for real-world applications.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the nature of the industry sector and the role and
                responsibilities of a plant operator.
              </li>
              <li>
                Learn the purpose of principal components, basic construction,
                controls, and terminology of piling rigs.
              </li>
              <li>
                Conform to manufacturer’s requirements as outlined in the
                operator’s handbook and relevant regulations.
              </li>
              <li>
                Perform pre-use checks to ensure machine safety and
                functionality.
              </li>
              <li>
                Configure and ready the piling rig for travel and operation.
              </li>
              <li>
                Explain procedures for traveling over rough or uneven terrain.
              </li>
              <li>Manoeuvre in confined spaces safely and efficiently.</li>
              <li>Position, configure, and set up for bored works.</li>
              <li>
                Identify and mitigate hazards, including underground and
                overhead services.
              </li>
              <li>
                Install displacement piles accurately and safely to completion.
              </li>
              <li>Comply with signals and instructions during operations.</li>
              <li>
                Maintain safe working conditions and adhere to safety
                regulations.
              </li>
              <li>
                Explain lifting requirements and limitations when using a piling
                rig.
              </li>
              <li>Carry out shutdown and securing procedures.</li>
              <li>
                Explain de-rigging, loading, and unloading procedures for
                transportation.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course combines classroom-based instruction with practical
              exercises. Participants will be assessed through CPCS technical
              tests to evaluate their knowledge and operational competence.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Candidates who pass the CPCS technical tests will receive a Red
              CPCS Trained Operator Card, valid for two years. To upgrade to a
              Blue CPCS Competence Card, participants must complete an NVQ Level
              2 in Plant Operations within the two-year period. The Blue Card is
              renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Piling Rig Bored Below 20 Tonnes A47 course equips
              participants with essential skills for operating piling rigs,
              focusing on safety, maintenance, and efficiency. It is suitable
              for both new and experienced operators seeking recognized
              certification and enhanced career opportunities.
            </p>
            <p>
              Completing this course enables participants to meet industry
              standards, improve employability, and achieve professional growth
              in the construction sector.
            </p>
            <p>
              Enroll today and take the first step toward becoming a certified
              piling rig operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
