import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSExcavator360Above10T() {
  return (
    <>
      <Helmet>
        <title>
          A59 Excavator 360º Above 10 Tonne Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join our CPCS Excavator 360 Above 10 Tonne Training course to gain essential skills and certification for operating 360 degree excavators above 10 tonnes."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A59 Excavator 360º Above 10 Tonne Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This 360 Excavator CPCS training course is aimed at people who
              require a formal qualification for operating a 360 degree
              excavator above 10 Tonne. We will conduct a learner analysis to
              identify the duration of excavator training required for those
              with limited or no experience, whereas experienced operators can
              attend a two-day experienced worker course.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Operate the excavator 360° tracked safely and productively.
              </li>
              <li>Fit and operate ancillary equipment.</li>
              <li>Site the machine safely and efficiently.</li>
              <li>
                Carry out minor adjustments and routine servicing procedures.
              </li>
              <li>
                Understand the capabilities, purposes and limitations of each
                machine.
              </li>
              <li>Follow all necessary safety precautions.</li>
              <li>
                Interpret the information provided by level pegs and profiles.
              </li>
              <li>
                Be aware of the necessary routine adjustments and service
                procedures, and carry them out.
              </li>
              <li>Work to levels with the aid of laser equipment.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Mixture of classroom-based teaching and practical exercises,
              followed by one day assessment - CPCS technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Learners will receive a Red CPCS Trained Operator Card on passing
              the CPCS technical tests, valid for two years. After gaining
              on-site experience, you must complete an NVQ Level 2 in Plant
              Operations - Preparing and operating 360 degree excavators within
              two years to apply for the blue CPCS Competence Card, which is
              renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Excavator 360 Above 10 Tonne Training Course equips
              participants with essential operational skills and
              industry-recognized certification. Join Alpha Construction
              Training to advance your career in construction machinery
              operation.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
