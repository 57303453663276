import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSkidSteerToolCarrier() {
  return (
    <>
      <Helmet>
        <title>
          D92 Skid Steer Tool Carrier Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join our CPCS Skid Steer Tool Carrier D92 Training Course to master skid steer loader operations and earn your CPCS Red Trained Operator Card."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>D92 Skid Steer Tool Carrier Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Embarking on the CPCS Skid Steer Tool Carrier D92 Course is a
              pivotal step towards enhancing your proficiency in handling skid
              steer loaders. These versatile machines are indispensable in
              construction, and our meticulously designed course provides a
              robust foundation and practical acumen necessary for operating
              them safely and efficiently.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the manufacturer’s requirements and safety
                precautions.
              </li>
              <li>Efficient operation and control of skid steer loaders.</li>
              <li>Routine checks and maintenance of skid steer equipment.</li>
              <li>
                Handling loaded and unloaded machines with safety in mind.
              </li>
              <li>Adhering to safe loading and unloading practices.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes both theoretical and practical assessments to
              ensure you gain the necessary knowledge and hands-on experience.
              This will equip you with the confidence to operate skid steer
              loaders safely and efficiently on any construction site.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, you will receive a CPCS Red Trained
              Operator Card, a recognized certification in the construction
              industry. This valuable credential will help you stand out and
              open doors to more job opportunities in construction.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Skid Steer Tool Carrier D92 Course covers essential
              aspects of operating skid steer loaders, with a strong focus on
              safety and operational efficiency. This course prepares you for
              the CPCS test and equips you with the necessary skills to excel in
              the construction sector.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
