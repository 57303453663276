import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTelescopicHandler360Slew() {
  return (
    <>
      <Helmet>
        <title>
          A77 Telescopic Handler 360 Slew Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Enroll in our CPCS Telescopic Handler 360 Slew (A77) Training Course to gain expertise in operating 360 slew telehandlers onsite."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A77 Telescopic Handler 360 Slew Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This Telescopic Handler 360 Slew (A77) CPCS training course is
              designed for individuals seeking a formal qualification to operate
              360 slew telehandlers onsite. The course supersedes the
              now-retired A17D category and ensures participants are equipped
              with the latest skills and knowledge for safe and efficient
              operations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>Roles and responsibilities of the operator.</li>
              <li>
                Pre-use and post-use checks, including shutdown and securing
                procedures.
              </li>
              <li>Lifting and placing suspended and varied loads safely.</li>
              <li>
                Configuring and setting the machine for duties and travel.
              </li>
              <li>
                Conducting activities such as slope work, high lifts, stacking,
                and destacking.
              </li>
              <li>
                Attaching and removing attachments, including hoist ropes.
              </li>
              <li>
                Basic principles of slinging loads using full slewing
                capabilities.
              </li>
              <li>
                Adhering to manufacturer requirements, regulations, and
                legislation.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course combines classroom-based teaching and practical
              exercises, culminating in a one-day CPCS technical test to assess
              your competency in operating a telescopic handler 360 slew.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, learners will receive a Red CPCS
              Trained Operator Card, valid for two years. To upgrade to the Blue
              CPCS Competence Card, participants must complete an NVQ Level 2 in
              Plant Operations within two years, which is renewable every five
              years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Demolition Plant – Pedestrian Operated D91 Training
              Course is an essential program for those looking to enhance their
              skills in demolition operations. Spanning multiple days, this
              competitively priced course provides theoretical knowledge and
              practical expertise, ensuring participants are fully prepared for
              industry demands. A recognized CPCS certification is awarded upon
              completion, paving the way for numerous career opportunities in
              the construction and demolition sectors.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
