import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function GritterSnowplough() {
  return (
    <>
      <Helmet>
        <title>
          N802 Gritter/Snowplough Training Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Enroll in Alpha Constructions' NPORS-certified training for Gritter / Snowplough operation. Gain essential skills and certification for safe and efficient machine use."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N802 Gritter/Snowplough Training Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to train operators in the safe and
              efficient use of gritters and snowploughs. It provides the
              necessary skills and knowledge to operate the machinery in line
              with industry best practices and NPORS guidelines.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Introduction to the machine, its controls, required PPE, and
                safe mounting/dismounting procedures.
              </li>
              <li>
                Conducting pre-start and running checks, understanding the
                machine's major components, features, and associated risks.
              </li>
              <li>
                Recognizing operator responsibilities and referring to the
                manufacturer's handbook for specific equipment.
              </li>
              <li>
                Operating procedures in accordance with NPORS guidelines and
                industry best practices.
              </li>
              <li>
                Safe parking and shutdown protocols to ensure operational
                safety.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Delegates will complete a multiple-choice theory test consisting
              of 25 questions, followed by a practical test to assess their
              competency in operating the machinery and adherence to safety
              standards.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              <strong>NPORS Traditional Card:</strong> Valid for 3-5 years, this
              internationally recognized card is suitable for non-construction
              applications.
            </p>
            <p>
              <strong>CSCS Embossed NPORS Card:</strong> Widely accepted in the
              UK construction industry, this card requires a valid CITB Health,
              Safety, and Environment test within two years of applying for the
              NPORS ticket.
            </p>
            <p>
              <strong>Types of NPORS CSCS Embossed Cards:</strong>
              <ul className="no-bullets">
                <li>
                  <strong>
                    NPORS CSCS Red Trained Operator Card (2 years):
                  </strong>{" "}
                  Issued upon completing training and/or the NPORS test with a
                  valid HS&E test.
                </li>
                <li>
                  <strong>
                    NPORS CSCS Blue Competent Operator Card (5 years):
                  </strong>{" "}
                  Awarded to operators who complete NPORS training/testing and
                  hold a relevant NVQ.
                </li>
              </ul>
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The N802 Gritter / Snowplough Training Course equips operators
              with the skills required for safe and effective use of gritters
              and snowploughs. Covering theoretical knowledge and practical
              application, the course ensures compliance with industry standards
              and NPORS guidelines. Successful participants receive NPORS
              certification, with options to upgrade to a Blue Competent
              Operator card upon completing NVQ requirements.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
