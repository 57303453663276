import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function ExcavatorCrane() {
  return (
    <>
      <Helmet>
        <title>N100 Excavator Crane Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Enroll in Alpha Constructions' NPORS-certified training for 180-degree excavator operation. This course covers essential theory and practical skills for safe and effective machine handling."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N100 Excavator Crane Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The objective of this course is to equip operators with the
              essential skills and knowledge to operate 180-degree excavators
              safely and efficiently. The training covers both theoretical
              foundations and practical applications, aligning with NPORS
              standards to ensure operators are fully prepared for industry
              demands. By the end of the course, participants will be competent
              in performing safe operations, understanding equipment
              limitations, and following best practices for excavator use in
              various work environments.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Develop a fundamental understanding of the industry and the
                responsibilities and risks associated with excavator operation.
              </li>
              <li>
                Follow manufacturer guidelines, comply with technical
                specifications, and adhere to relevant regulations.
              </li>
              <li>
                Locate, identify, and explain safe working loads, interpret
                lifting capacity charts, and understand various lifting
                configurations.
              </li>
              <li>
                Recognize and differentiate between lifting procedures and
                assess tasks within each category.
              </li>
              <li>
                Identify load centers of gravity, estimate load weights, and
                address potential overhead hazards.
              </li>
              <li>
                Establish communication with the slinger/signaller to ensure
                safe movement and placement zones using signals or codes.
              </li>
              <li>
                Confirm the excavator’s safety for attachment fittings and
                secure load integrity by conducting a trial lift.
              </li>
              <li>
                Lift and move loads safely, with minimal uncontrolled movement
                to designated positions.
              </li>
              <li>Practice environmental awareness during operation.</li>
              <li>Perform end-of-service and securing procedures correctly.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a theory assessment with multiple-choice
              questions and a practical evaluation to ensure all necessary
              skills and safety practices are met.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              <strong>NPORS Traditional Card:</strong> This card is valid for 5
              years and is widely recognized within housebuilding, utilities,
              and marine sectors.
            </p>
            <p>
              <strong>NPORS with CSCS Logo:</strong> Recognized by major
              contractors, the initial RED Trained Operator card is valid for 2
              years. It can be upgraded to the BLUE Competent Operator card upon
              NVQ completion.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This course provides comprehensive training on safe and effective
              operation of 180-degree excavators. Through a blend of theory and
              practice, operators gain essential skills for industry competency,
              making them eligible for NPORS certification.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
