import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSLoaderCompressor() {
  return (
    <>
      <Helmet>
        <title>
          A41 Loader Compressor Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join the CPCS Loader Compressor A41 training course at Alpha Construction Training to gain essential skills for operating loader compressors safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A41 Loader Compressor Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The Loader Compressor A41 CPCS training course is designed to
              equip participants with the knowledge and practical skills needed
              to operate loader compressors safely and efficiently. This course
              focuses on developing operational proficiency and adherence to
              industry standards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the principles of loader compressor operation and the
                functions of its components and attachments.
              </li>
              <li>
                Perform pre-use checks and routine maintenance to ensure machine
                safety and reliability.
              </li>
              <li>
                Safely operate the machine in various site conditions, including
                loading, leveling, and using compressed air tools.
              </li>
              <li>
                Follow industry-standard safety protocols and site procedures.
              </li>
              <li>
                Learn shutdown and securing procedures for loader compressors.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course combines theoretical instruction with hands-on
              practical exercises. Participants will undergo a CPCS technical
              test that evaluates their knowledge and competence in operating
              loader compressors safely and effectively.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive a Red CPCS Trained Operator
              Card, valid for two years. To progress to a Blue CPCS Competence
              Card, participants must complete an NVQ Level 2 in Plant
              Operations within this period. The Blue Card is renewable every
              five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Loader Compressor A41 course provides a thorough
              understanding of loader compressor operations, safety protocols,
              and maintenance. It caters to both novices and experienced
              operators seeking to enhance their skills and gain recognized
              certification.
            </p>
            <p>
              Key benefits of the course include increased employability, career
              advancement opportunities, and alignment with the latest industry
              standards.
            </p>
            <p>
              Enroll today to take the first step towards becoming a proficient
              loader compressor operator and advancing your career in the
              construction industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
