import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSMEWPMastClimber() {
  return (
    <>
      <Helmet>
        <title>
          A27 MEWP Mast Climber Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join the A27 CPCS MEWP Mast Climber training course at Alpha Construction Training and become a certified operator in mast climbers."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A27 MEWP Mast Climber Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS A27 Mobile Elevating Work Platform – Mast Climber course
              provides comprehensive training for individuals involved in the
              operation of mast climbers. This course covers essential
              theoretical knowledge and practical skills required to safely and
              efficiently operate mast climbers in various construction and
              industrial settings.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the components, functionalities, and proper operation
                of mast climbers.
              </li>
              <li>
                Adhere to relevant safety regulations, standards, and procedures
                governing mast climber operations.
              </li>
              <li>
                Conduct pre-operational inspections to ensure equipment safety.
              </li>
              <li>
                Operate mast climbers efficiently, including lifting, extending,
                and maneuvering mechanisms.
              </li>
              <li>
                Interpret load charts and ensure safe operation within capacity
                ratings.
              </li>
              <li>
                Navigate and position mast climbers for various tasks, including
                work at elevated heights.
              </li>
              <li>
                Set up mast climbers in suitable locations with stable ground
                conditions.
              </li>
              <li>
                Manage environmental factors affecting mast climber operations.
              </li>
              <li>
                Respond effectively to emergencies, including shutdown protocols
                and evacuation procedures.
              </li>
              <li>
                Perform routine maintenance checks and inspections to ensure
                reliability and safety.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Assessment is conducted through a combination of written
              examinations and practical assessments. Participants must
              demonstrate proficiency in operating mast climbers and
              understanding key safety principles to receive certification.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course, participants will
              receive a CPCS certification demonstrating their competency in
              operating mast climbers.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A27 MEWP Mast Climber course is an all-encompassing training
              program combining theoretical knowledge and practical skills to
              ensure participants can safely and effectively operate mast
              climbers. With expert guidance and a focus on industry best
              practices, this course prepares you for certification and a
              successful career in mast climber operations.
            </p>
            <p>
              Enroll today to gain your CPCS certification and expand your
              expertise in mobile elevating work platforms.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
