import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/nvq.css";
import { Helmet } from "react-helmet";

export default function Nvq() {
  return (
    <>
      <Helmet>
        <title>NVQ Training & Assessments | Alpha Construction Training</title>
        <meta
          name="description"
          content="Explore NVQ certification options at Alpha Construction Training, offering nationally recognized qualifications in construction and plant operations. Learn about eligibility, assessments, as well as categories for professional development."
        />
      </Helmet>
      <Navbar />
      <div className="nvq-container">
        <div className="nvq-hero">
          <h1>NVQ Training & Assessments</h1>
        </div>

        <div className="nvq-content">
          <div className="nvq-info">
            <div className="nvq-description">
              <h2>What is NVQ?</h2>
              <p>
                The National Vocational Qualification (NVQ) is a highly regarded
                certification in the UK, tailored to validate practical skills
                and theoretical knowledge within specific trades or industries.
                It is work-based and emphasizes competence demonstrated in
                real-world settings, making it ideal for professionals in
                construction, plant operations, and other technical fields.
              </p>
              <br />
              <p>
                At Alpha Construction Training, we offer NVQ certification
                assessments at competitive rates, with discounts for group
                registrations. Achieving an NVQ demonstrates your proficiency
                and helps you advance your career with recognized
                qualifications.
              </p>
            </div>
          </div>

          <div className="nvq-requirements">
            <div className="requirements-content">
              <h2>Introduction</h2>
              <p>
                The NVQ (National Vocational Qualification) is a widely
                recognized certification designed to assess a candidate’s
                practical skills and knowledge in a specific occupation. It is
                essential for individuals aspiring to establish or advance their
                careers in the construction industry. By attaining an NVQ,
                candidates not only demonstrate their competence in performing
                job-specific tasks but also their adherence to industry
                regulations and safety protocols.
              </p>
              <br />
              <p>
                To enroll in an NVQ assessment, candidates are required to hold
                a valid CITB Health, Safety & Environment Test certificate. This
                certificate verifies that the individual possesses the
                foundational understanding of health and safety principles
                critical for working on construction sites and other related
                environments. Such knowledge ensures that candidates can operate
                in a manner that prioritizes the safety of themselves and
                others.
              </p>
              <br />
              <p>
                Furthermore, most NVQ assessments require candidates to have
                prior experience in their chosen field of work. This
                prerequisite allows them to effectively demonstrate the
                practical application of their skills and ensures they meet the
                high standards set by the qualification. The combination of
                prior knowledge, hands-on experience, and a robust NVQ framework
                equips candidates with the credentials needed to excel in their
                careers.
              </p>
              <br />
              <p>
                Whether you're looking to enhance your qualifications, comply
                with professional standards, or open doors to advanced
                opportunities, the NVQ serves as a reliable benchmark of your
                professional capabilities. It reflects not only your technical
                expertise but also your commitment to continuous development and
                workplace safety.
              </p>
            </div>

            <div className="assessment-info">
              <h2>About the NVQ Assessment</h2>
              <h3 className="assessment-heading">NVQ Theory Assessment</h3>
              <p>
                The NVQ theory assessment tests your understanding of industry
                regulations, safety protocols, and best practices. Questions may
                cover topics like equipment operation, risk management, and
                legal compliance. A passing score is typically required to
                proceed to the practical phase.
              </p>
              <h3 className="assessment-heading">NVQ Practical Assessment</h3>
              <p>
                The practical assessment evaluates your hands-on skills in real
                or simulated work environments. Tasks include setting up
                machinery, operating it safely, and adhering to proper shutdown
                procedures. The assessment duration and complexity depend on the
                qualification level and trade.
              </p>
              <p>
                Observers or assessors will evaluate your ability to complete
                tasks efficiently and in compliance with safety standards.
                Feedback is provided, and successful candidates receive their
                NVQ certificate.
              </p>
            </div>
          </div>

          <div className="nvq-categories">
            <div className="categories-list">
              <div className="category">
                <h2>NVQ Categories</h2>
                <p>
                  NVQs are available in a wide range of categories and levels to
                  suit various professions. Examples include:
                </p>
                <ul>
                  <li>Construction Plant Operations</li>
                  <li>Site Supervision</li>
                  <li>Demolition Operations</li>
                  <li>Bricklaying</li>
                  <li>Carpentry</li>
                  <li>Painting and Decorating</li>
                  <li>Steel Fixing</li>
                  <li>Dry Lining</li>
                  <li>Electrical Installation</li>
                  <li>Plumbing and Heating</li>
                  <li>Groundworks</li>
                  <li>Roofing</li>
                </ul>
                <p>
                  Contact us for a full list of NVQ categories available or to
                  discuss your specific qualification needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
