import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function IndustrialForkliftCourse() {
  return (
    <>
      <Helmet>
        <title>
          N001 Industrial Counterbalanced Forklift Course | Alpha Constructions
        </title>
        <meta
          name="description"
          content="Learn to safely operate an industrial counterbalanced forklift with Alpha Constructions' NPORS-certified training. Gain essential skills for forklift operation."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N001 Industrial Counterbalanced Forklift Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course trains participants to operate an industrial
              counterbalanced forklift truck safely and efficiently. It includes
              theoretical and practical training tailored to participants'
              experience levels, ensuring compliance with industry standards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understanding the industry, potential hazards, and operator
                responsibilities.
              </li>
              <li>
                Familiarity with the manufacturer's handbook for the specific
                forklift truck being used.
              </li>
              <li>
                Identifying major components of the forklift and understanding
                their functions.
              </li>
              <li>
                Understanding steering, driving, and braking controls and their
                functions.
              </li>
              <li>
                Conducting pre-operational checks in compliance with
                manufacturer and legislative requirements.
              </li>
              <li>Safe mounting and dismounting procedures.</li>
              <li>
                Starting and stopping the engine, and safely moving the
                forklift.
              </li>
              <li>
                Configuring the forklift for travel and maneuvering it safely,
                both laden and unladen, in various environments.
              </li>
              <li>Performing safety checks at the work area.</li>
              <li>
                Configuring the forklift for lifting and load handling tasks.
              </li>
              <li>
                Lifting and transferring loads safely and accurately at
                different locations.
              </li>
              <li>Loading and unloading external transport safely.</li>
              <li>Fitting, adjusting, and removing attachments.</li>
              <li>
                Understanding loading and unloading procedures for machine
                transportation.
              </li>
              <li>Performing end-of-shift and shutdown procedures.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will complete a theory assessment comprising open and
              multiple-choice questions, followed by a practical assessment to
              evaluate their operational and safety skills.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              <strong>Existing NPORS Card Holder – No (S)NVQ Held:</strong> A
              Trained Operator card, valid for 2 years, is issued to operators
              without the requisite (S)NVQ. During this period, operators must
              register for and achieve the necessary (S)NVQ to obtain further
              certification.
            </p>
            <p>
              <strong>Existing NPORS Card Holder – (S)NVQ Held:</strong> A
              Competent Operator card, valid for 5 years, is issued to operators
              with the requisite (S)NVQ. These operators also receive a CPD
              record/operator logbook to track their professional development
              and workplace operating hours.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The N001 Industrial Counterbalanced Forklift Training equips
              participants with the skills needed to safely operate industrial
              forklifts in diverse environments. The course emphasizes safety,
              compliance, and operational efficiency, leading to NPORS
              certification tailored to operators' qualifications and experience
              levels.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
