import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function MicroExcavator() {
  return (
    <>
      <Helmet>
        <title>N016 Micro Excavator 360° Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Enroll in Alpha Constructions' NPORS-certified training for Micro Excavator 360° (<1 Tonne) operation. Gain essential skills for safe and efficient machine handling."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N016 Micro Excavator 360° Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This c ourse is designed to train operators in the safe and
              efficient use of Micro Excavators (360°, 1 Tonne). It emphasizes
              practical skills and theoretical knowledge to align with NPORS
              standards. Participants will gain the competency to operate this
              machinery in diverse work environments, ensuring safety,
              operational accuracy, and adherence to industry best practices.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understand the fundamental risks and responsibilities associated
                with operating a Micro Excavator.
              </li>
              <li>
                Identify and explain the functionality of key components,
                controls, and attachments of the excavator.
              </li>
              <li>
                Conduct pre-use inspections and daily maintenance procedures in
                compliance with safety guidelines.
              </li>
              <li>
                Maneuver the machine in open and confined spaces, ensuring safe
                handling on uneven terrains.
              </li>
              <li>
                Perform efficient excavation, material handling, and load
                placement with precision and environmental awareness.
              </li>
              <li>
                Safely install, operate, and remove excavator attachments.
              </li>
              <li>
                Apply risk management strategies to prevent accidents and
                mitigate workplace hazards.
              </li>
              <li>
                Execute proper end-of-service shutdown and securing procedures
                to maintain operational integrity.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Delegates will undergo a theory assessment featuring
              multiple-choice questions, followed by a hands-on practical test
              to evaluate their understanding of safe operation, machine
              control, and task execution.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              <strong>NPORS Traditional Card:</strong> Valid for 5 years, this
              certification is widely recognized in construction and associated
              sectors.
            </p>
            <p>
              <strong>NPORS with CSCS Logo:</strong> Requires the CITB Health,
              Safety, and Environment Test. The RED Trained Operator card is
              valid for 2 years and can be upgraded to the BLUE Competent
              Operator card upon NVQ completion.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The N016 Micro Excavator 360° (1 Tonne) Course offers a blend of
              theoretical and practical learning, enabling operators to handle
              these machines confidently and safely. Upon completion, delegates
              will be eligible for NPORS certification, demonstrating their
              competence and readiness for the workplace.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
