import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function VehicleMarshal() {
  return (
    <>
      <Helmet>
        <title>N403 Vehicle Marshal Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about the NPORS N403 Vehicle Marshal Training Course, including course objectives, learning outcomes, assessment, and certification options."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N403 Vehicle Marshal Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed for individuals involved in the movement
              of vehicles, providing health and safety knowledge alongside
              practical guidance on safely directing vehicle operations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understand the industry, relevant legislation, associated
                hazards, and the responsibilities of a vehicle marshal.
              </li>
              <li>
                Gain familiarity with the manufacturer’s handbook for the
                vehicle/machine being guided.
              </li>
              <li>
                Learn and use approved hand signals, signs, and alternative
                forms of communication.
              </li>
              <li>
                Identify and maintain appropriate PPE for vehicle marshal
                operations.
              </li>
              <li>
                Conduct essential safety checks, including stop blocks and
                tipping areas.
              </li>
              <li>
                Establish and manage exclusion zones during unloading
                operations.
              </li>
              <li>
                Safely and effectively guide vehicles in forward and reverse
                directions, including in confined spaces and blind areas.
              </li>
              <li>
                Understand environmental considerations when performing duties.
              </li>
              <li>Perform end-of-shift shut down and parking procedures.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>To achieve certification, candidates must complete:</p>
            <ul>
              <li>
                NPORS Theory assessment comprising open questions and
                multiple-choice.
              </li>
              <li>NPORS Practical assessment.</li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive an NPORS card based on the
              following criteria:
            </p>
            <ul>
              <li>
                <strong>Existing NPORS Card Holder – No NVQs Held:</strong> A
                Trained Operator card valid for two years. During this period,
                operators must achieve the requisite NVQs with unit credits
                applicable to their categories.
              </li>
              <li>
                <strong>Existing NPORS Card Holder – NVQs Held:</strong> A
                Competent Operator card valid for five years, along with a CPD
                record/operator logbook documenting their professional
                development and workplace hours.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The Vehicle Marshal course equips participants with essential
              skills and knowledge to safely and effectively manage the movement
              of vehicles on site, ensuring compliance with industry standards
              and best practices.
            </p>
          </div>
        </div>
        <div className="contact-us-section">
          <button
            className="contact-us-button"
            onClick={() => (window.location.href = "/contactus")}
          >
            Contact Us
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
