import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSkidSteerLoader() {
  return (
    <>
      <Helmet>
        <title>
          A23 Skid Steer Loader Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Enroll in the A23 CPCS Skid Steer Loader training course at Alpha Construction Training and gain industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A23 Skid Steer Loader Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is aimed at individuals requiring a
              formal qualification for operating a Skid Steer Loader. The
              training duration is determined through a learner analysis to
              accommodate both novices and experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Name and explain the purpose of principle components, basic
                construction, controls, and terminology of the skid steer
                loader.
              </li>
              <li>
                Adhere to manufacturer requirements as outlined in the
                operator’s handbook and other relevant regulations and
                legislation.
              </li>
              <li>
                Conduct pre-use checks and configure the plant for site travel.
              </li>
              <li>
                Travel over rough, uneven ground, substantial inclines, and
                level surfaces, both laden and unladen.
              </li>
              <li>Maneuver the skid steer loader in confined spaces safely.</li>
              <li>
                Set up and operate the loader for extraction and loading tasks.
              </li>
              <li>
                Address hazards and manage underground and overhead services
                effectively.
              </li>
              <li>
                Extract materials, form stockpiles, and construct temporary
                ramps.
              </li>
              <li>
                Sort and place materials into transport vehicles or hoppers.
              </li>
              <li>Backfill, spread, and level materials as required.</li>
              <li>Perform plant shut down and securing procedures.</li>
              <li>
                Explain and follow loading and unloading procedures for machine
                transportation.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course includes a mixture of classroom-based teaching and
              practical exercises, culminating in a one-day assessment through
              CPCS technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Learners will receive a Red CPCS Trained Operator Card upon
              successfully passing the technical tests, valid for two years. To
              upgrade to a Blue CPCS Competence Card, participants must complete
              an NVQ Level 2 in Plant Operations within the two-year validity
              period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A23 Skid Steer Loader course equips participants with the
              knowledge and practical expertise to safely and efficiently
              operate this machinery. Combining theoretical instruction with
              hands-on practice, the course prepares learners for certification
              and future responsibilities.
            </p>
            <p>
              Enroll today to achieve your CPCS certification and advance your
              career in construction equipment operation.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
