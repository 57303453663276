import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSMEWPBoom() {
  return (
    <>
      <Helmet>
        <title>A26 MEWP Boom Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Master the skills to operate MEWPs with the CPCS Mobile Elevating Work Platform – Boom A26 training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A26 MEWP Boom Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Mobile Elevating Work Platform – Boom A26 Training Course
              is designed to provide learners with the essential skills and
              knowledge to operate mobile elevating work platforms (MEWPs)
              safely and efficiently.
            </p>
            <p>
              Whether you are a novice seeking foundational knowledge or an
              experienced professional refreshing your skills, this course
              caters to all competency levels.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>Understand and apply operational safety measures.</li>
              <li>Perform routine inspection and maintenance of MEWPs.</li>
              <li>Execute emergency procedures effectively.</li>
              <li>Operate mobile elevating work platforms proficiently.</li>
              <li>
                Comprehend relevant laws, standards, and safety protocols.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of classroom-based teaching and
              practical exercises. Assessment is conducted on the final day
              through CPCS technical tests, evaluating both theoretical
              knowledge and practical competence.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Participants who successfully pass the CPCS technical tests will
              be awarded the CPCS Red Trained Operator Card, valid for two
              years. To obtain the Blue CPCS Competence Card, participants must
              complete an NVQ Level 2 in Plant Operations within the two-year
              validity period. The Blue Card is renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Mobile Elevating Work Platform – Boom A26 Training Course
              equips learners with comprehensive skills and knowledge to operate
              MEWPs effectively. Through a blend of theoretical and practical
              sessions, participants will be prepared for both CPCS technical
              tests and real-world operations.
            </p>
            <p>
              Join us at Alpha Construction Training to enhance your expertise
              and gain industry-recognized certification. Enroll today and
              elevate your career in construction and maintenance.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
