import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTrackedLoadingShovel() {
  return (
    <>
      <Helmet>
        <title>
          A22 Tracked Loading Shovel Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Enroll in the A22 CPCS Tracked Loading Shovel training course at Alpha Construction Training and gain industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A22 Tracked Loading Shovel Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is aimed at individuals aspiring to
              enhance their skills in operating a Tracked Loading Shovel. The
              training duration is customized based on a learner analysis to
              suit both beginners and experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Conduct pre-operational checks and basic maintenance of the
                tracked loading shovel.
              </li>
              <li>Operate and handle the machine safely and efficiently.</li>
              <li>Load, unload, and stockpile materials effectively.</li>
              <li>
                Execute emergency procedures and manage hazards proficiently.
              </li>
              <li>Prepare the machine for work and ensure load security.</li>
              <li>
                Take the machine out of service and prepare it for road
                transport.
              </li>
              <li>
                Understand and mitigate risks related to underground and
                overhead services.
              </li>
              <li>Maintain a safe and tidy working environment.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course includes a combination of classroom-based teaching and
              practical exercises, culminating in a one-day assessment through
              CPCS technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Learners will receive a Red CPCS Trained Operator Card upon
              successfully passing the technical tests, valid for two years. To
              upgrade to a Blue CPCS Competence Card, participants must complete
              an NVQ Level 2 in Plant Operations within the two-year validity
              period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A22 Tracked Loading Shovel course is designed to provide
              participants with the essential knowledge and practical skills
              needed to operate this machinery safely and effectively. By
              combining theoretical instruction with hands-on practice, the
              course ensures learners are fully prepared for certification and
              future job responsibilities.
            </p>
            <p>
              Enroll today to achieve your CPCS certification and take the next
              step in your construction career.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
