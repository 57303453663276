import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function PaverCourse() {
  return (
    <>
      <Helmet>
        <title>N220 Paver Course | Alpha Constructions</title>
        <meta
          name="description"
          content="NPORS-certified training course for safe road construction practices, paving safety, machine setup, and maintenance. Suitable for all skill levels."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N220 Paver Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course provides essential training for individuals seeking to
              gain knowledge and practical skills in road construction
              processes. It covers both theoretical and practical elements to
              ensure operators can perform their roles safely and effectively.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>Understanding of the road construction process.</li>
              <li>Paving safety practices.</li>
              <li>Machine setup and application techniques.</li>
              <li>Use of mechanical and automatic control systems.</li>
              <li>Routine machine maintenance.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>Participants will undergo the following assessments:</p>
            <ul>
              <li>NPORS Theory Assessment: Multiple-choice format.</li>
              <li>NPORS Practical Assessment: Hands-on evaluation.</li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants can obtain one of the
              following NPORS cards:
            </p>
            <ul>
              <li>
                Traditional Card: No NVQ required. Valid for 3-5 years. For the
                "HS&E Tested Logo," the CITB Health, Safety, and Environment
                Test must have been passed within the last two years.
              </li>
              <li>
                Trained Operator Card (RED): For operators without an NVQ. Valid
                for 2 years, during which the requisite NVQ must be completed.
              </li>
              <li>
                Competent Operator Card (BLUE): For operators with the requisite
                NVQ. Valid for 5 years, with a CPD record/operator logbook
                issued.
              </li>
            </ul>
            <p>
              Operators must provide proof of passing the CITB Health, Safety, &
              Environmental test within the last two years for any card
              application.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This training ensures participants gain a deep understanding of
              road construction techniques, paving safety, and machine
              operation. Suitable for both novice and experienced operators, it
              adheres to NPORS standards, emphasizing practical and theoretical
              skills.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
