import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function RoadPlaner() {
  return (
    <>
      <Helmet>
        <title>N216 Road Planer Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn how to safely and competently operate Road Planers on-site with our NPORS Road Planer (N216) course. Suitable for all experience levels."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N216 Road Planer Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course aims to equip participants with the skills and
              knowledge needed to operate road planers safely and effectively,
              following NPORS guidelines. The course is tailored for all skill
              levels, from beginners to experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Introduction to the road planer, its controls, and the required
                personal protective equipment (PPE), along with safe mounting
                and dismounting practices.
              </li>
              <li>
                Conducting pre-start and running checks, understanding major
                machine components, their features, benefits, and associated
                risks.
              </li>
              <li>
                Understanding operator responsibilities, including the use of
                the manufacturer's handbook specific to the machine.
              </li>
              <li>
                Learning safe and efficient operating procedures in compliance
                with industry best practices and NPORS guidelines.
              </li>
              <li>
                Implementing safe parking and shutdown procedures to ensure
                operational safety.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>Participants will undergo:</p>
            <ul>
              <li>
                <strong>Theory Assessment:</strong> Multiple-choice questions.
              </li>
              <li>
                <strong>Practical Assessment:</strong> Hands-on evaluation of
                road planer operating skills.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive one of the following NPORS
              cards:
            </p>
            <ul>
              <li>
                <strong>Traditional Card:</strong> Valid for 3–5 years. No NVQ
                required, but passing the CITB HS&E Test is necessary for the
                “HS&E TESTED” logo.
              </li>
              <li>
                <strong>Trained Operator Card (RED):</strong> Valid for 2 years.
                NVQ completion within this period is required for upgrading.
              </li>
              <li>
                <strong>Competent Operator Card (BLUE):</strong> Valid for 5
                years for candidates with the requisite NVQ and includes a CPD
                logbook for professional development.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS Road Planer (N216) course provides practical and
              theoretical training for operators to safely and efficiently
              handle road planers in compliance with NPORS standards. The course
              is ideal for operators of all experience levels and emphasizes
              safety, maintenance, and proper operation techniques.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
