import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSHoist() {
  return (
    <>
      <Helmet>
        <title>A20 Hoist Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Gain formal qualifications with the A20 CPCS Hoist training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A20 Hoist Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is aimed at people who require a formal
              qualification for operating a Hoist. Training duration will be
              determined through a learner analysis to accommodate both
              beginners and experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Describe the nature of the sector and your role and
                responsibilities as an equipment operator.
              </li>
              <li>
                Name the types of hoists and explain the purpose of their
                principal components, basic construction, controls, and
                terminology.
              </li>
              <li>
                Conform to manufacturer requirements as outlined in the
                operator’s manual and other relevant regulations and guidance.
              </li>
              <li>
                Explain hoist working limitations and perform all pre-use checks
                effectively.
              </li>
              <li>
                Undertake running checks, including gates and safety devices,
                before placing into service.
              </li>
              <li>
                Ensure stability, system alignment, and hoist-way protection are
                in position before use.
              </li>
              <li>
                Verify hoist capacity to accommodate loads, equipment, or
                passengers.
              </li>
              <li>
                Carry out full emergency lowering and evacuation procedures
                (Endorsements B & D).
              </li>
              <li>
                Prepare the hoist for the required load(s) or passengers and
                identify the suitability of loads for transport.
              </li>
              <li>
                Operate the hoist to the end of travel and to various landing
                levels, both loaded and unloaded.
              </li>
              <li>
                Comply with signals, instructions, and safety protocols to
                maintain safe operation.
              </li>
              <li>
                Place the hoist in an out-of-service condition, isolate, and
                secure after use.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes classroom-based teaching and practical
              exercises, followed by a one-day assessment consisting of CPCS
              technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Learners will receive a Red CPCS Trained Operator Card upon
              successfully passing the technical tests, valid for two years. To
              upgrade to a Blue CPCS Competence Card, participants must complete
              an NVQ Level 2 in Plant Operations within the two-year validity
              period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A20 Hoist course provides comprehensive training to ensure
              participants are equipped with the knowledge and skills required
              for safe and effective hoist operation. Combining theoretical and
              practical elements, this training prepares learners for
              certification and workplace success.
            </p>
            <p>
              Enroll today to achieve your CPCS certification and advance your
              career in equipment operation and site management.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
