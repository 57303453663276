import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSCraneSupervisor() {
  return (
    <>
      <Helmet>
        <title>A62 Crane Supervisor Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Enroll in our CPCS Crane Supervisor (A62) Training Course to gain comprehensive knowledge and certification in crane supervision, lifting operations, and safety."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A62 Crane Supervisor Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course provides the essential training and certification
              needed to become a qualified Crane Supervisor. Designed for
              individuals with prior experience in crane operations, it covers
              the critical knowledge and skills required to supervise lifting
              operations safely and efficiently.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Legislation, regulations, and Codes of Practice relating to
                lifting duties.
              </li>
              <li>
                Developing and implementing safe systems of work, including
                method statements.
              </li>
              <li>
                Understanding personal responsibility in lifting operations.
              </li>
              <li>
                Familiarity with different types of cranes and associated
                terminology.
              </li>
              <li>
                Interpreting and understanding crane documentation and duty
                charts.
              </li>
              <li>
                Appreciating crane stability, safety devices, and operational
                guidelines.
              </li>
              <li>
                Observing and adhering to British Standard 7121 for the safe use
                of cranes.
              </li>
              <li>
                Defining the roles of crane supervisors and appointed persons.
              </li>
              <li>
                Preparing work areas, including setting up exclusion zones.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes three days of learning through a combination
              of classroom-based teaching and practical exercises, followed by a
              one-day assessment. The CPCS technical tests evaluate your
              understanding and ability to supervise crane operations
              effectively.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive a Red CPCS Trained Operator
              Card, valid for two years. To progress to the Blue CPCS Competence
              Card, learners must complete an NVQ Level 4 in Controlling Lifting
              Operations - Supervising Lifts within two years. The Blue Card is
              renewable every five years.
            </p>
          </div>

          <div className="entry-requirements">
            <h2>Entry Requirements</h2>
            <p>
              Learners must have passed the Supervisors Health, Safety, and
              Environment Test within the last two years prior to the course
              start date. Additionally, candidates should have sufficient
              experience with cranes to meet the requirements specified in
              British Standard 7121 Safe Use of Cranes. A good understanding of
              spoken and written English is also essential.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
