import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTrencher() {
  return (
    <>
      <Helmet>
        <title>A37 Trencher Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Trencher A37 training course at Alpha Construction Training to gain essential skills and certification for operating trenchers."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A37 Trencher Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Trencher A37 Training Course is designed to equip
              participants with the essential knowledge and hands-on skills
              required to operate trenching equipment safely and efficiently.
              Whether you're a novice or a seasoned operator, this course is
              tailored to suit both new learners and experienced individuals
              looking for a skills refresh. Our programme also ensures you meet
              CPCS certification requirements, paving the way for a successful
              career in construction.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the components and operational techniques of
                trenchers.
              </li>
              <li>
                Learn how to conduct pre-start checks and maintenance procedures
                for trenching equipment.
              </li>
              <li>
                Gain skills for operating trenchers over various terrains,
                levels, and inclines, including radius cutting.
              </li>
              <li>
                Develop proficiency in setting up the machine for safe and
                efficient operation.
              </li>
              <li>
                Master trenching techniques for laying pipes, cables, and
                drainage systems.
              </li>
              <li>
                Understand how to safely maneuver the trencher in confined
                spaces and over different terrain types.
              </li>
              <li>
                Learn safety protocols for handling hazards, underground, and
                overhead services.
              </li>
              <li>
                Be able to safely shut down and secure the equipment after use.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course includes a blend of classroom-based instruction and
              practical exercises, followed by a one-day assessment. The
              assessment will test your proficiency in operating the trencher
              safely and effectively through CPCS technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will receive a Red CPCS
              Trained Operator Card, valid for two years. To upgrade to a Blue
              CPCS Competence Card, you must complete an NVQ Level 2 in Plant
              Operations within two years of obtaining the Red Card.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Trencher A37 Training Course is designed to provide both
              theoretical knowledge and practical experience in operating
              trenching equipment. This course will prepare you to handle a wide
              variety of real-world construction challenges and gain the
              certification necessary to advance your career in plant
              operations.
            </p>
            <p>
              Enroll today to take the next step towards becoming a certified
              and proficient Trencher operator.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
