import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSCrawlerTractorDozer() {
  return (
    <>
      <Helmet>
        <title>
          A34 Crawler Tractor / Dozer Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join the CPCS Crawler Tractor / Dozer A34 training course at Alpha Construction Training to gain essential skills and certification for operating crawler dozers."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A34 Crawler Tractor / Dozer Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Crawler Tractor / Dozer A34 Training Course equips
              participants with the essential knowledge and practical skills
              required for the safe and efficient operation of crawler dozers.
              This course is tailored to meet the needs of both novice and
              experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the roles and responsibilities of a plant operator in
                the construction sector.
              </li>
              <li>
                Familiarize yourself with the principal components, basic
                construction, controls, and terminology of crawler dozers.
              </li>
              <li>
                Conduct pre-use checks and comply with manufacturer requirements
                and regulations.
              </li>
              <li>
                Configure and set the crawler dozer for site travel and
                excavating duties.
              </li>
              <li>
                Safely travel over rough terrain, inclines, and confined spaces.
              </li>
              <li>
                Perform various excavation tasks, construct ramps, and shape
                stockpiles.
              </li>
              <li>
                Grade, spread, and level ground and materials effectively.
              </li>
              <li>
                Understand hazards, underground and overhead services, and how
                to mitigate risks.
              </li>
              <li>Shut down and secure the crawler dozer after use.</li>
              <li>
                Learn loading and unloading procedures for machine transport.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of classroom-based teaching and
              practical exercises, followed by a one-day assessment comprising
              CPCS technical tests. Participants must demonstrate their
              operational proficiency and understanding of safety principles.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will receive a Red CPCS
              Trained Operator Card, valid for two years. To upgrade to a Blue
              CPCS Competence Card, participants must complete an NVQ Level 2 in
              Plant Operations within two years of obtaining the Red Card.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This CPCS Crawler Tractor / Dozer A34 Training Course combines
              targeted training with practical experience to prepare operators
              for real-world challenges. Whether you are new to operating
              crawler dozers or looking to formalize your skills, this course
              offers a comprehensive pathway to certification and career
              advancement.
            </p>
            <p>
              Enroll today to begin your journey towards CPCS certification and
              professional excellence in crawler dozer operation.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
