import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSkipHandler() {
  return (
    <>
      <Helmet>
        <title>A39 Skip Handler Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Skip Handler A39 training course at Alpha Construction Training to learn essential skills for operating skip handlers safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A39 Skip Handler Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Skip Handler A39 Training Course is designed to provide
              participants with the essential skills and knowledge needed to
              operate skip handlers safely and efficiently. Whether you're new
              to the industry or an experienced operator looking to update your
              skills, this course caters to all proficiency levels. Our
              programme adheres to the highest industry standards and ensures
              you are well-prepared for real-world construction challenges.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Master the correct operational techniques for loading,
                unloading, and maneuvering skip handlers.
              </li>
              <li>
                Understand the importance of safety protocols and learn how to
                comply with regulations.
              </li>
              <li>
                Learn how to conduct routine maintenance and troubleshoot common
                issues with skip handlers.
              </li>
              <li>
                Develop skills to safely position and maneuver skip handlers,
                particularly for road transportation.
              </li>
              <li>
                Gain expertise in managing safe working loads and preparing
                skips for transport.
              </li>
              <li>
                Learn how to adapt to various environmental and site-specific
                conditions when operating a skip handler.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course combines both practical, hands-on experience and
              theoretical instruction, followed by an assessment of your skills
              and knowledge. You will demonstrate your competence in operating a
              skip handler safely and efficiently as part of the CPCS technical
              assessment.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will be awarded a Red
              CPCS Trained Operator Card, which is valid for two years. To
              upgrade to a Blue CPCS Competence Card, you must complete an NVQ
              Level 2 in Plant Operations within two years of receiving the Red
              Card.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Skip Handler A39 Training Course is designed to provide
              you with a well-rounded understanding of skip handler operations,
              from safety protocols to routine maintenance. This training
              ensures you are fully prepared to operate skip handlers
              competently and safely in various construction environments.
            </p>
            <p>
              Enroll today to start your journey toward becoming a certified and
              proficient skip handler operator, with the skills needed to thrive
              in the construction sector.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
