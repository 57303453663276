import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function LoadingShovelCourse() {
  return (
    <>
      <Helmet>
        <title>N209 Loading Shovel Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn to operate a loading shovel safely and effectively with NPORS-certified training at Alpha Constructions. Gain essential skills and certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N209 Loading Shovel Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course provides comprehensive training for safe and efficient
              operation of a loading shovel. It includes tailored training
              durations for learners with varying levels of experience, from
              beginners to experienced operators requiring assessment.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understanding the industry, potential hazards, and operator
                responsibilities.
              </li>
              <li>
                Familiarity with the manufacturer's handbook for the specific
                loading shovel being used.
              </li>
              <li>
                Identifying major components of the loading shovel and their
                functions.
              </li>
              <li>
                Understanding key controls and their functions for efficient
                operation.
              </li>
              <li>
                Conducting pre-operational checks in compliance with
                manufacturer and legislative requirements.
              </li>
              <li>
                Identifying and maintaining PPE appropriate for loading shovel
                use.
              </li>
              <li>
                Performing safety checks at the work area before operation.
              </li>
              <li>
                Preparing and operating the loading shovel safely and
                efficiently.
              </li>
              <li>
                Understanding environmental considerations during operation.
              </li>
              <li>
                Proper storage and transportation of implements and attachments.
              </li>
              <li>
                Demonstrating knowledge of loading and unloading procedures for
                machine transportation.
              </li>
              <li>
                Completing end-of-shift and shutdown procedures effectively.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undergo a theory assessment, comprising open and
              multiple-choice questions, and a practical assessment to
              demonstrate their competency in operating the loading shovel.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              <strong>Existing NPORS Card Holder – No (S)NVQ Held:</strong> A
              Trained Operator card valid for 2 years is issued to operators who
              do not hold the requisite (S)NVQ. During this period, operators
              must achieve the required (S)NVQ for further certification.
            </p>
            <p>
              <strong>Existing NPORS Card Holder – (S)NVQ Held:</strong> A
              Competent Operator card valid for 5 years is issued to operators
              holding the requisite (S)NVQ. These operators also receive a CPD
              record/operator logbook to document their professional development
              and operational hours.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The N209 Loading Shovel Training equips participants with the
              necessary knowledge and practical skills to operate a loading
              shovel safely. This course is suitable for operators across
              various experience levels, focusing on safety, compliance, and
              operational efficiency to meet NPORS standards.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
