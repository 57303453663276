import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function CPCSCrawlerCrane() {
  return (
    <>
      <Helmet>
        <title>
          A02 CPCS Crawler Crane Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Enroll in the A02 Crawler Crane training course at Alpha Construction Training. Gain expertise in crawler crane operations, safety, and receive CPCS certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A02 CPCS Crawler Crane Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is meticulously designed to provide aspiring operators
              with the essential skills and knowledge to safely and competently
              operate a crawler crane in various construction environments. It
              ensures a comprehensive understanding of operational techniques
              and safety protocols.
            </p>
            <p>
              CPCS certification gained through this course is a recognized
              qualification in the construction industry, enhancing your
              employability and career prospects.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                An understanding of the roles and responsibilities of a crawler
                crane operator.
              </li>
              <li>
                Mastery of operational controls and functions of crawler cranes,
                including maneuvering and load handling.
              </li>
              <li>
                Safety protocols for preventing accidents, recognizing crane
                signals, and understanding the impact of jib length and shock
                loading.
              </li>
              <li>
                Techniques for handling, lifting, and transferring loads safely
                and efficiently.
              </li>
              <li>
                Maintenance procedures, including routine checks and minor
                adjustments to cranes and lifting gear.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undertake a rigorous assessment, including a
              theory test and practical evaluation. The theory test covers
              safety procedures, operational principles, and load-handling
              techniques. The practical assessment ensures hands-on proficiency
              in operating a crawler crane.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              On successful completion of the course, you will receive the CPCS
              certification. This includes a Red Trained Operator card valid for
              2 years, with the opportunity to upgrade to a Blue Competent
              Operator card upon achieving a relevant NVQ. CPCS certification is
              recognized across the UK, ensuring you are equipped for various
              construction roles.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              Over 10 days of intensive training, this course combines
              theoretical instruction with practical experience, preparing you
              for real-world challenges. You will gain a deep understanding of
              crawler crane operations, enabling you to confidently and safely
              handle these powerful machines on site.
            </p>
            <p>
              Led by industry experts in state-of-the-art facilities, the A02
              Crawler Crane Course is your pathway to a rewarding career in
              construction.
            </p>
          </div>

          <div className="contact-us-section">
            <Link to="/contactus" className="contact-us-button">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
