import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSConveyorPump() {
  return (
    <>
      <Helmet>
        <title>A75 Conveyor Pump Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join our CPCS Conveyor Pump (A75) Training Course to master the operation, maintenance, and safety protocols for conveyor pumps."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A75 Conveyor Pump Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Embark on a journey to enhance your skills in the construction
              sector with our comprehensive Conveyor Pump A75 course. This
              program equips participants with essential knowledge and hands-on
              experience to operate conveyor pumps proficiently, meeting the
              highest industry standards.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>Operational mechanics of conveyor pumps.</li>
              <li>Safety protocols and best practices.</li>
              <li>Maintenance and troubleshooting techniques.</li>
              <li>Setting up, configuring, and maneuvering the equipment.</li>
              <li>Anchoring and securing pipes and lines effectively.</li>
              <li>Cleaning and completing documentation accurately.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course combines theoretical instruction with practical
              exercises, culminating in CPCS technical tests. These assessments
              evaluate participants' competency in safely and effectively
              operating conveyor pumps.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Participants who successfully complete the course will receive the
              CPCS A75 certification. This esteemed qualification validates
              expertise in conveyor pump operation and significantly boosts
              employability in the construction industry.
            </p>
          </div>

          <div className="entry-requirements">
            <h2>Entry Requirements</h2>
            <p>
              Participants must have passed the Operatives Health, Safety, and
              Environment Test within the last two years. Proficiency in spoken
              and written English is essential for effective participation in
              the course.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
