import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSScreener() {
  return (
    <>
      <Helmet>
        <title>A43 Screener Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the CPCS Screener A43 training course at Alpha Construction Training to gain essential skills for operating screeners safely and efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A43 Screener Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The Screener A43 CPCS training course is aimed at providing
              participants with the knowledge and practical skills needed to
              operate screeners safely and efficiently. This course ensures
              adherence to industry standards and prepares participants for
              real-world applications.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the nature of the industry sector and the role and
                responsibilities of a plant operator.
              </li>
              <li>
                Name and explain the purpose of principal components, basic
                construction, controls, and terminology.
              </li>
              <li>
                Conform with manufacturer’s requirements as outlined in the
                operator’s handbook and relevant regulations.
              </li>
              <li>
                Perform pre-use checks to ensure machine safety and
                functionality.
              </li>
              <li>
                Configure and set the machine for travel over various terrains
                and confined spaces.
              </li>
              <li>
                Operate the screener to produce aggregate of specified sizes,
                including configuring for different duties.
              </li>
              <li>
                Sort materials into graded sizes and control workflow rates
                effectively.
              </li>
              <li>Apply proper communication procedures during operations.</li>
              <li>Handle blockages and foreign object removal safely.</li>
              <li>Perform shutdown and securing procedures.</li>
              <li>
                Explain loading, unloading, and disassembly procedures for
                machine transport.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a combination of classroom-based instruction
              and practical exercises. It concludes with a CPCS technical test
              to assess the participant's knowledge and operational competence.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive a Red CPCS Trained Operator
              Card, valid for two years. To upgrade to a Blue CPCS Competence
              Card, participants must complete an NVQ Level 2 in Plant
              Operations within the two-year period. The Blue Card is renewable
              every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Screener A43 course provides comprehensive training on
              operating screeners effectively, focusing on safety, maintenance,
              and efficiency. It caters to both new and experienced operators
              looking to enhance their skills and achieve recognized
              certification.
            </p>
            <p>
              By completing this course, participants will boost their
              employability, open doors to career advancement, and align with
              the latest industry standards.
            </p>
            <p>
              Enroll today and take the first step toward becoming a proficient
              screener operator in the construction industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
