import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPlantDriving() {
  return (
    <>
      <Helmet>
        <title>
          A68 CPCS Plant Driving Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Master the skills required to operate plant machinery with the A68 CPCS Plant Driving course at Alpha Construction Training. Achieve industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A68 CPCS Plant Driving Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to equip participants with the knowledge
              and skills needed to safely and efficiently operate plant
              machinery in compliance with CPCS standards. It provides the
              foundational training required to handle various types of plant
              machinery on construction sites.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the operational principles and controls of plant
                machinery.
              </li>
              <li>
                Conducting pre-use checks and maintaining machinery for optimal
                performance.
              </li>
              <li>
                Operating plant machinery safely in diverse site conditions.
              </li>
              <li>
                Recognizing potential hazards and implementing safe working
                practices.
              </li>
              <li>
                Effective communication and coordination with site personnel.
              </li>
              <li>
                Compliance with legal and regulatory requirements for plant
                operations.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undertake both theoretical and practical
              evaluations to demonstrate their understanding and proficiency in
              operating plant machinery. The course concludes with a CPCS
              technical test that must be passed to achieve certification.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will receive the CPCS Red
              Trained Operator Card, valid for two years. They can progress to
              obtaining an NVQ Level 2 to qualify for the CPCS Blue Competence
              Card, renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A68 CPCS Plant Driving Course delivers essential training for
              operating plant machinery safely and effectively on construction
              sites. Combining theoretical instruction with hands-on practice,
              this course ensures participants are well-prepared to meet
              industry standards.
            </p>
            <p>
              Enroll today to gain industry-recognized certification and enhance
              your career opportunities in plant operations.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
