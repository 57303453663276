import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSForwardTippingDumper() {
  return (
    <>
      <Helmet>
        <title>
          A09B CPCS Forward Tipping Dumper Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Master the operation of forward tipping dumpers with the CPCS A09B course at Alpha Construction Training. Gain industry-recognized certification today."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A09B CPCS Forward Tipping Dumper Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is specifically designed to equip participants with
              the skills and knowledge required to safely and effectively
              operate forward tipping dumpers. It ensures compliance with CPCS
              standards while preparing individuals for real-world scenarios in
              the construction sector.
            </p>
            <p>
              Whether you’re new to operating machinery or looking to formalize
              your experience, this course offers a pathway to achieving
              professional qualifications and enhancing your career
              opportunities.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the roles and responsibilities of a forward tipping
                dumper operator.
              </li>
              <li>
                Learn to perform pre-operational checks and adhere to
                manufacturer’s guidelines.
              </li>
              <li>
                Develop skills to safely travel over various terrains, both
                loaded and unloaded.
              </li>
              <li>
                Configure and set up the dumper for work and ensure suitability
                of loading and tipping areas.
              </li>
              <li>
                Master procedures for loading, transferring, and discharging
                materials safely.
              </li>
              <li>
                Understand hazard management, including overhead and underground
                services, and ensure safe shutdown and securing of equipment.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course involves both theoretical instruction and hands-on
              practical exercises. A comprehensive assessment is conducted at
              the end of the course, including the CPCS technical test, to
              confirm your competency and understanding of dumper operations.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful participants will receive the CPCS Red Trained Operator
              Card, valid for two years. During this period, you can pursue an
              NVQ Level 2 qualification, which leads to the CPCS Blue Competence
              Card, renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Forward Tipping Dumper (A09B) Course provides a
              comprehensive foundation in the operation, maintenance, and safety
              requirements of forward tipping dumpers.
            </p>
            <p>
              Combining theoretical knowledge with practical training, this
              course equips you with the expertise needed to operate forward
              tipping dumpers efficiently and safely. Enroll today to advance
              your skills and earn industry-recognized certification.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
