import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function Screener() {
  return (
    <>
      <Helmet>
        <title>N208 Screener Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn about the NPORS N208 Screener Training Course, including key skills, assessment, and certification options for Red and Blue NPORS cards."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N208 Screener Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The N208 Screener Training Course aims to provide operators with
              the skills and knowledge required to safely and efficiently
              operate a screener, in compliance with industry best practices and
              NPORS guidelines.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Introduction to the screener, relevant controls, and required
                PPE, including safe mounting/dismounting practices.
              </li>
              <li>
                Conducting pre-start and running checks, identifying major
                components, and understanding their features, benefits, and
                associated risks.
              </li>
              <li>
                General responsibilities of the operator, with reference to the
                manufacturer’s handbook for the specific machine.
              </li>
              <li>
                Operating procedures in line with industry best practices and
                NPORS guidelines.
              </li>
              <li>Safe parking and shut-down procedures.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              To complete the NPORS N208 Screener Training, candidates must
              pass:
            </p>
            <ul>
              <li>A multiple-choice theory test (25 questions).</li>
              <li>
                A practical test to demonstrate safe and efficient operation of
                the screener.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will obtain an NPORS card upon completion,
              with options including:
            </p>
            <ul>
              <li>
                <strong>NPORS Traditional Card:</strong> Valid for 3 to 5 years,
                recognized internationally but less accepted in construction.
              </li>
              <li>
                <strong>CSCS Embossed NPORS Card:</strong> Widely accepted in
                the UK construction industry. Requires a valid HS&E test within
                the last 2 years.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <ul>
              <li>
                <strong>NPORS CSCS Red Trained Operator Card (2 years):</strong>{" "}
                Issued after training/testing with a valid HS&E test.
              </li>
              <li>
                <strong>
                  NPORS CSCS Blue Competent Operator Card (5 years):
                </strong>
                Issued to operators who complete NVQ training alongside NPORS
                testing.
              </li>
            </ul>
          </div>
        </div>
        <div className="contact-us-section">
          <button
            className="contact-us-button"
            onClick={() => (window.location.href = "/contactus")}
          >
            Contact Us
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
