import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function QuickHitchAwareness() {
  return (
    <>
      <Helmet>
        <title>N726 Quick Hitch Awareness Course | Alpha Constructions</title>
        <meta
          name="description"
          content="NPORS Quick Hitch Awareness training for operatives using quick hitch systems in construction and allied environments. Focused on safety and operational requirements."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N726 Quick Hitch Awareness Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed for operatives working with machinery
              equipped with quick hitch systems. Participants will learn the
              operational and safety requirements for using quick hitches
              effectively and safely in construction and related industries.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understand the industry, associated dangers, and
                responsibilities as a plant operator.
              </li>
              <li>
                Gain familiarity with the manufacturer’s handbook for specific
                quick hitch systems.
              </li>
              <li>
                Identify and explain the major components and functions of quick
                hitch devices.
              </li>
              <li>
                Recognize and maintain appropriate PPE for quick hitch use.
              </li>
              <li>Understand different types of quick hitch devices.</li>
              <li>
                Perform pre-operational checks according to manufacturer and
                legislative requirements.
              </li>
              <li>Conduct safety checks at designated change-over areas.</li>
              <li>Fit and remove attachments safely and efficiently.</li>
              <li>Demonstrate secure attachment checks.</li>
              <li>Complete end-of-shift shutdown and parking procedures.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>Participants will undergo:</p>
            <ul>
              <li>
                **NPORS Theory Assessment**: Open and multiple-choice questions.
              </li>
              <li>
                **NPORS Practical Assessment**: Hands-on demonstration of
                skills.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful participants will receive the following certification
              options:
            </p>
            <ul>
              <li>
                **NPORS Traditional Operator Card**: Ideal for industries
                outside the construction sector where a CSCS logo is not
                required. Valid for 3 or 5 years, depending on specific sector
                or company requirements.
              </li>
              <li>
                **NPORS / CSCS Operator Card**: Required for the construction
                sector and follows the trained operator-to-competent operator
                card route, which involves NVQ achievement.
              </li>
            </ul>
            <p>
              All applicants must pass the CITB Health, Safety & Environmental
              test within 2 years prior to certification application.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS Quick Hitch Awareness course equips operatives with the
              knowledge and skills to safely and effectively use quick hitch
              systems. This training ensures compliance with safety standards
              and promotes operational excellence across construction and allied
              environments.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
