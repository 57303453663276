import { useEffect, useRef, useState } from "react";
import "../css/navbar.css";
import logo from "../images/alphaconstructiontraining.png";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="logo-container">
        <a href="/">
          <img
            src={logo}
            alt="Alpha Construction Training Logo"
            className="logo"
          />
        </a>
      </div>

      <div className={`nav-links ${isOpen ? "open" : ""}`} ref={menuRef}>
        <a href="/cpcs">CPCS</a>
        <a href="/npors">NPORS</a>
        <a href="/nvq">NVQ</a>
        <a href="/contactus">Contact Us</a>
      </div>

      <div className="burger-icon" onClick={toggleMenu}>
        ☰
      </div>
    </nav>
  );
}
