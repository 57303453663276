import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../css/cpcs.css";
import { Helmet } from "react-helmet";
import crawlerCrane from "../images/a02-crawlercrane.png";
import industrialForklift from "../images/a16-industrialforklift.jpg";

export default function Cpcs() {
  return (
    <>
      <Helmet>
        <title>CPCS Training & Assessments | Alpha Construction Training</title>
        <meta
          name="description"
          content="Explore comprehensive CPCS training and assessment options at Alpha Construction Training. Learn about the Construction Plant Competence Scheme (CPCS), requirements, theory and practical assessments, and various machinery categories for plant operators across the UK."
        />
      </Helmet>
      <Navbar />
      <div className="cpcs-container">
        <div className="cpcs-hero">
          <h1>CPCS Training & Assessments</h1>
        </div>
        <div className="cpcs-content">
          <div className="cpcs-info">
            <div className="cpcs-description">
              <h2>What is CPCS?</h2>
              <p>
                The Construction Plant Competence Scheme (CPCS) is a highly
                regarded, card-based certification program that plays a key role
                in verifying the competence and skills of plant operators in the
                UK. As the largest and most widely recognized certification
                scheme in the plant industry, CPCS ensures that operators
                possess the necessary technical knowledge, practical skills, and
                health and safety awareness to safely and efficiently operate
                plant machinery.
              </p>
              <br />
              <p>
                The CPCS program is designed to assess both theoretical
                knowledge and practical abilities, ensuring that operators can
                demonstrate their competence in a range of machine operations.
                It includes a combination of theoretical tests, practical
                assessments, and real-world evaluations, all conducted under
                strict safety and industry standards.
              </p>
              <br />
              <p>
                Our CPCS training courses are competitively priced to ensure
                accessibility for all operators, and we also offer attractive
                discounts for those who are undertaking multiple tests. This
                allows operators to gain multiple certifications while managing
                training costs effectively.
              </p>
            </div>
          </div>
          <div className="cpcs-requirements">
            <div className="requirements-content">
              <h2>Introduction</h2>
              <p>
                The Construction Plant Competence Scheme (CPCS) is one of the
                most recognized and respected certification programs for plant
                operators in the UK. To begin any training or assessments within
                this scheme, candidates are required to hold the CITB Health &
                Safety Certification. This certification ensures that all
                candidates have a solid understanding of the fundamental health
                and safety practices necessary for working on construction sites
                and operating plant machinery.
              </p>
              <br />
              <p>
                The CITB Health & Safety Test serves as a foundational
                requirement for the CPCS qualification process. It is designed
                to assess a candidate's knowledge of key safety procedures and
                risk management protocols specific to the construction and plant
                operations industries. The test covers topics such as site
                safety, safe working practices, emergency response procedures,
                and the safe operation of plant machinery. Without successfully
                completing this test, candidates cannot proceed with the CPCS
                qualification or apply for any CPCS Cards.
              </p>
              <br />
              <p>
                By ensuring that all candidates meet these health and safety
                standards, the CPCS and CITB work together to maintain a high
                level of safety and competence across the construction industry.
                This requirement is in place to protect workers, reduce the risk
                of accidents, and ensure that operators are properly prepared
                for the responsibilities of their roles.
              </p>
            </div>
            <div className="assessment-info">
              <h2>About the CPCS Assessment</h2>
              <h3 className="assessment-heading">CPCS Theory Test</h3>
              <p>
                The CPCS Theory Test is a verbal assessment that measures a
                candidate's understanding of plant machinery or their specific
                role in operations.
              </p>
              <br />
              <p>
                Format: This test is conducted in person and is audio recorded.
                Candidates are provided with a copy of the questions in sequence
                and must respond verbally without writing their answers.
              </p>
              <br />
              <p>
                Content: The number of questions depends on the category, most
                tests include 40 questions. These are divided into:
              </p>
              <br />
              <p>
                - 15 safety-related questions specific to the category <br />-
                25 eneral knowledge questions selected from a broader question
                pool
              </p>
              <br />
              <p>
                Time: The standard duration of the test is 30 minutes. However,
                additional time may be granted for candidates requiring support
                or interpreters.
              </p>
              <br />
              <p>
                Passing: To pass the test, candidates must achieve the minimum
                required number of correct answers.
              </p>

              <h3 className="assessment-heading">CPCS Practical Test</h3>
              <p>
                The CPCS Practical Test is a hands-on evaluation designed to
                assess a candidate's ability to safely and effectively operate
                machinery in compliance with CPCS standards. The test includes a
                series of exercises that simulate real-life tasks, ensuring
                candidates are prepared for actual worksite operations.
              </p>
              <br />
              <p>
                Before beginning the test, candidates must complete a pre-test
                briefing where the assessor explains the tasks, safety
                expectations, and rules. Candidates are given an opportunity to
                ask questions to clarify the requirements.
              </p>
              <br />
              <p>
                The test starts with pre-start checks, where candidates must
                inspect the machinery for any defects or issues, such as fluid
                levels, tire conditions, and operational functionality. This
                demonstrates the candidate's understanding of essential
                maintenance and safety practices.
              </p>
              <br />
              <p>
                Following the pre-start checks, candidates move on to traveling
                tasks, which involve maneuvering the machinery across various
                terrains, ensuring stability, avoiding obstacles, and
                demonstrating awareness of the environment. These tasks evaluate
                control and precision.
              </p>
              <br />
              <p>
                During the setup for work phase, candidates prepare the machine
                for a specific task, such as fitting attachments or positioning
                the equipment correctly. This part of the test measures
                attention to detail and the ability to follow instructions.
              </p>
              <br />
              <p>
                The operating phase focuses on task execution, where candidates
                are required to use the machinery to perform specific
                operations, such as lifting, digging, loading, or unloading.
                This phase tests the candidate's skill, efficiency, and ability
                to follow safety protocols.
              </p>
              <br />
              <p>
                Finally, the test concludes with shutdown procedures, where
                candidates safely power down and secure the machinery, including
                parking it in a designated area and performing post-operation
                checks to ensure it is safe for future use.
              </p>
              <br />
              <p>
                Throughout the test, candidates are closely monitored by a
                certified CPCS assessor who evaluates their performance against
                standardized criteria. The assessor ensures that all tasks are
                completed in accordance with health and safety regulations and
                industry best practices.
              </p>
              <br />
              <p>
                The duration of the practical test depends on the type of
                machinery being assessed. For example, operating a forklift may
                take less time compared to a complex piece of equipment like a
                crane. Candidates must demonstrate competence in all areas to
                successfully pass the test and earn their certification.
              </p>
            </div>
          </div>
          <div className="cpcs-categories">
            <div className="categories-list">
              <div className="category">
                <h2>NPORS Categories</h2>
                <div className="button-list">
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-crawlercrane")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text"> A02 Crawler Crane</div>
                      <div class="button-image">
                        <img src={crawlerCrane} alt="Crawler Crane" />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-industrialforklift")
                    }
                  >
                    <div class="button-content">
                      <div class="button-text">A16 Industrial Forklift</div>
                      <div class="button-image">
                        <img
                          src={industrialForklift}
                          alt="Industrial Forklift"
                        />
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-agriculturaltractor")
                    }
                  >
                    A33 Agricultural Tractor
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-forwarddumper")
                    }
                  >
                    A09 Forward Tipping Dumper
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-liftingoperations")
                    }
                  >
                    A61 Lifting Operations
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-plantdriving")
                    }
                  >
                    A68 Plant Driving
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-towercrane")}
                  >
                    A04 Tower Crane
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-plantmarshaller")
                    }
                  >
                    A73 Plant Marhsaller
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-excavatorabove5t")
                    }
                  >
                    A12 Excavator Above 5T
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-excavatorbelow5t")
                    }
                  >
                    A10 Excavator Below 5T
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-roughterrainforklift")
                    }
                  >
                    A14 Rough Terrain Forklift
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-sideloader")}
                  >
                    A15 Side Loader
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-reachtruck")}
                  >
                    A18 Reach Truck Forklift
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-grader")}
                  >
                    A19 Grader
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-hoist")}
                  >
                    A20 Hoist
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-wheeledshovel")
                    }
                  >
                    A21 Wheeled Shovel
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-trackedloadingshovel")
                    }
                  >
                    A22 Tracked Loading Shovel
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-skidsteerloader")
                    }
                  >
                    A23 Skid Steer Loader
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-motorisedscraper")
                    }
                  >
                    A24 Motorised Scraper
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-mewpscissor")}
                  >
                    A25 MEWP Scissor
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-mewpmastclimber")
                    }
                  >
                    A27 MEWP Master Climber
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigtripod")
                    }
                  >
                    A30 Piling Rig Tripod
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-crawlertractordozer")
                    }
                  >
                    A34 Crawler - Tractor/Dozer
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-crawlertractorsideboom")
                    }
                  >
                    A35 Crawler Tractor Side Boom
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-trencher")}
                  >
                    A37 Trencher
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-skiphandler")}
                  >
                    A39 Skip Handler
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-slingersignaller")
                    }
                  >
                    A40 Slinger Signaller
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-loadersignaller")
                    }
                  >
                    A41 Loader Compressor
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-crusher")}
                  >
                    A42 Crusher
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-screener")}
                  >
                    A43 Screener
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-trailermountedpump")
                    }
                  >
                    A44 Trailer Mounted Concrete Pump
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigbelow20t")
                    }
                  >
                    A45 Piling Rig Driven Below 20 Tonnes
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigabove20t")
                    }
                  >
                    A46 Piling Rig Driven Above 20 Tonnes
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigbelow20t")
                    }
                  >
                    A47 Piling Rig Bored Below 20 Tonnes
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-loadersecurer")
                    }
                  >
                    A50 Loader Securer
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-telescopichandler")
                    }
                  >
                    A17 Telescopic Handler
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-rideonroller")
                    }
                  >
                    A31 Ride-on-Roller
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-mewpboom")}
                  >
                    A26 MEWP Boom
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-dragline")}
                  >
                    A05 Dragline
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-dumptruckarticulated")
                    }
                  >
                    A56 Dump Truck Articulated
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-dumptruckrigid")
                    }
                  >
                    A57 Dump Truck Rigid
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-excavator360above10t")
                    }
                  >
                    A59 Excavator 360 Above 10T
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-excavator360below10t")
                    }
                  >
                    A58 Excavator 360 Below 10T
                  </button>
                  <button
                    onClick={() => (window.location.href = "/cpcs-mobilecrane")}
                  >
                    A60 Mobile Crane
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-compactcrane")
                    }
                  >
                    A66 Compact Crane
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigattendant")
                    }
                  >
                    A74 Piling Rig Attendant
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-concreteplacingboom")
                    }
                  >
                    A72 Concrete Placing Boom
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-demolitionplant")
                    }
                  >
                    D90 Demolition Plant
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-skidsteertoolcarrier")
                    }
                  >
                    D92 Skid Steer Tool Carrier
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-pilingrigboredabove20t")
                    }
                  >
                    A48 Piling Rig Bored Above 20 Tonnes
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-soillandfillcompactor")
                    }
                  >
                    A32 Soil LandFill Compactor
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-cranesupervisor")
                    }
                  >
                    A62 Crane Supervisor
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-overheadcrane")
                    }
                  >
                    A64 Overhead Crane
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-tunellinglocomotives")
                    }
                  >
                    A67 Tunnelling Locomotives
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-soilstabiliser")
                    }
                  >
                    A71 Soil Stabiliser
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-conveyorpump")
                    }
                  >
                    A75 Conveyor Pump
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-telescopichandler360slew")
                    }
                  >
                    A77 Telescopic Handler 360 Slew
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = "/cpcs-vacuumexcavator")
                    }
                  >
                    A78 Vacuum Excavator
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href =
                        "/cpcs-demolitionplantpedestrianoperated")
                    }
                  >
                    D91 Demolition Plant Pedestrian Operated
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
