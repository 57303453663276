import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function MEWPBoomCourse() {
  return (
    <>
      <Helmet>
        <title>N108 MEWP Boom Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Gain expertise in operating Mobile Elevating Work Platforms with NPORS-certified N108 MEWP Boom training at Alpha Constructions. Learn safe and efficient operation techniques."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N108 MEWP Boom Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The NPORS N108 MEWP Boom Training is designed to equip operators
              with the skills and knowledge required to safely and efficiently
              operate Mobile Elevating Work Platforms (MEWP) Boom equipment. It
              caters to individuals of all experience levels, from beginners to
              seasoned operators seeking certification.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Gaining a foundational understanding of the industry, hazards,
                and operator responsibilities.
              </li>
              <li>
                Familiarizing with the manufacturer's handbook and other
                information sources, including relevant legislation.
              </li>
              <li>
                Identifying and understanding the functions of major machine
                components.
              </li>
              <li>
                Locating and explaining the operation of steering, driving, and
                braking controls.
              </li>
              <li>
                Performing pre-operational and emergency lowering function
                checks according to standards.
              </li>
              <li>Safely mounting and dismounting the machine.</li>
              <li>
                Preparing the machine for travel and maneuvering it on various
                terrains and in confined spaces.
              </li>
              <li>
                Conducting safety checks at the work area, identifying overhead
                and underground hazards.
              </li>
              <li>
                Configuring the machine for accessing tasks at varying heights
                up to full operating height.
              </li>
              <li>
                Traveling with the platform raised (where applicable) and
                ensuring operational safety.
              </li>
              <li>
                Understanding loading and unloading procedures for machine
                transportation.
              </li>
              <li>
                Performing end-of-shift and shut-down procedures in line with
                best practices.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course involves a comprehensive evaluation process, including
              an NPORS Theory assessment consisting of open and multiple-choice
              questions, alongside a practical assessment to demonstrate
              hands-on proficiency.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Participants who complete the course successfully will receive an
              NPORS ID card:
            </p>
            <ul>
              <li>
                **Trained Operator Card**: Valid for 2 years, for operators
                without the required NVQ. These operators must achieve the NVQ
                within this timeframe to retain their certification.
              </li>
              <li>
                **Competent Operator Card**: Valid for 5 years, awarded to
                operators holding the requisite NVQ. This certification includes
                a CPD logbook for tracking professional development and
                operating hours.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS N108 MEWP Boom Course provides comprehensive training in
              the safe operation of Mobile Elevating Work Platforms. The course
              is adaptable to varying experience levels, ensuring that all
              participants gain valuable skills aligned with industry standards.
              With flexible training durations and expert instructors, this
              course prepares operators for real-world challenges.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
