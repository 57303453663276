import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSPilingRigTripod() {
  return (
    <>
      <Helmet>
        <title>
          A30 Piling Rig Tripod Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join the CPCS Piling Rig - Tripod A30 training course at Alpha Construction Training and gain essential skills and certification for piling rig operations."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A30 Piling Rig Tripod Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Piling Rig – Tripod A30 Training Course provides
              essential knowledge and practical skills for the proficient
              operation of piling rigs. This course adheres to the highest
              safety standards, ensuring participants understand the operational
              nuances of piling rig equipment.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the fundamentals of piling rig operations and their
                applications in construction.
              </li>
              <li>
                Adhere to safety protocols and comply with relevant industry
                standards.
              </li>
              <li>
                Perform pre-operational checks and routine maintenance on piling
                rig equipment.
              </li>
              <li>
                Travel with, set up, and operate a Piling Rig – Tripod safely
                and efficiently.
              </li>
              <li>
                Assess environmental considerations and ensure the rig’s
                suitability for planned operations.
              </li>
              <li>
                Troubleshoot common issues and perform minor equipment repairs.
              </li>
              <li>Shut down and secure the rig following operational tasks.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Assessment is conducted through a combination of written
              examinations and practical assessments. Participants must
              demonstrate proficiency in operating piling rigs and understanding
              key safety principles to receive certification.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course, participants will
              receive a CPCS certification, validating their competency in
              operating a Piling Rig – Tripod. This certification is widely
              recognized across the construction industry.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The Piling Rig – Tripod A30 Training Course combines in-depth
              theoretical knowledge with hands-on practical experience to ensure
              participants are well-equipped to operate piling rigs safely and
              efficiently. Whether you're starting your career or enhancing your
              skills, this course is a gateway to advanced opportunities in the
              construction industry.
            </p>
            <p>
              Enroll today and take the first step towards achieving your CPCS
              certification and mastering the operation of Piling Rig – Tripod
              equipment.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
