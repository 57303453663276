import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function MEWPScissorCourse() {
  return (
    <>
      <Helmet>
        <title>N109 MEWP Scissor Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Advance your skills in operating Mobile Elevating Work Platforms with NPORS-certified N109 MEWP Scissor training at Alpha Constructions. Learn industry-standard safety and operational techniques."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N109 MEWP Scissor Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The NPORS N109 MEWP Scissor Training provides operators with the
              knowledge and skills required to operate scissor lift platforms
              safely and effectively. Designed for all experience levels, this
              course ensures participants are equipped to handle real-world
              scenarios with confidence and competence.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understanding the industry, potential hazards, and operator
                responsibilities.
              </li>
              <li>
                Familiarizing with the manufacturer's handbook, other
                information sources, and applicable legislation.
              </li>
              <li>
                Locating and identifying major components, as well as steering,
                driving, and braking controls, and explaining their functions.
              </li>
              <li>
                Performing pre-operational checks and testing emergency lowering
                functions.
              </li>
              <li>Safely mounting and dismounting the scissor lift machine.</li>
              <li>
                Configuring the machine for travel and maneuvering it safely on
                level ground, inclines, and in confined areas.
              </li>
              <li>
                Conducting safety checks at the worksite, including identifying
                overhead and underground hazards.
              </li>
              <li>
                Operating the scissor lift to perform accessing tasks at various
                heights, up to full operational height.
              </li>
              <li>
                Using platform extension sections to enhance accessibility
                (where applicable).
              </li>
              <li>
                Traveling with the platform raised while ensuring operational
                safety (where applicable).
              </li>
              <li>
                Understanding loading and unloading procedures for
                transportation.
              </li>
              <li>
                Following end-of-shift and shutdown procedures according to
                industry best practices.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a comprehensive assessment process,
              comprising:
            </p>
            <ul>
              <li>
                Theory Assessment: Open questions and multiple-choice sections
                to test knowledge.
              </li>
              <li>
                Practical Assessment: Hands-on evaluation to ensure operational
                proficiency.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will receive an NPORS ID
              card. Certification options include:
            </p>
            <ul>
              <li>
                Trained Operator Card: Valid for 2 years, issued to operators
                without the requisite NVQs. Participants must complete the NVQs
                within this period to maintain certification.
              </li>
              <li>
                Competent Operator Card: Valid for 5 years, for operators with
                the requisite NVQs. A CPD record/logbook is also provided to
                track professional development and operating hours.
              </li>
            </ul>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS N109 MEWP Scissor Training offers comprehensive coverage
              of scissor lift operation techniques. With adaptable training
              durations, expert instruction, and industry-relevant content, this
              course is ideal for anyone seeking to enhance their operational
              skills and achieve NPORS certification.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
