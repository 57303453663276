import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function PlantMachineryMarshal() {
  return (
    <>
      <Helmet>
        <title>N133 Plant Machinery Marshal Course | Alpha Constructions</title>
        <meta
          name="description"
          content="NPORS-certified training for Plant Machinery Marshals. Covers legislation, safety procedures, communication, and guiding machinery efficiently."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N133 Plant Machinery Marshal Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course equips participants with the necessary skills and
              knowledge to operate as a Plant Machinery Marshal safely and
              effectively. The training covers legislation, safety checks,
              communication methods, and guiding machinery in various
              environments.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understand the industry, relevant legislation, and operator
                responsibilities.
              </li>
              <li>
                Familiarize with the manufacturer’s handbook for guided
                machinery.
              </li>
              <li>
                Learn approved hand signals, signs, and communication methods
                for efficient guidance.
              </li>
              <li>
                Identify and maintain appropriate PPE for machinery marshals.
              </li>
              <li>
                Perform safety checks, including stop blocks and tipping areas.
              </li>
              <li>
                Establish exclusion zones for loading and unloading activities.
              </li>
              <li>
                Guide machinery safely in forward, reverse, confined spaces, and
                “blind areas.”
              </li>
              <li>
                Understand environmental considerations during operations.
              </li>
              <li>
                Carry out end-of-shift procedures, including shutdown and
                parking.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>Participants will be evaluated through:</p>
            <ul>
              <li>
                **NPORS Theory Assessment**: Open questions and multiple choice.
              </li>
              <li>
                **NPORS Practical Assessment**: Hands-on evaluation of skills.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful participants will be awarded one of the following NPORS
              cards:
            </p>
            <ul>
              <li>
                Trained Operator Card (RED): Valid for 2 years for those without
                an NVQ. NVQ completion within this period is required to
                upgrade.
              </li>
              <li>
                Competent Operator Card (BLUE): Valid for 5 years for those with
                the requisite NVQ, including a CPD logbook for continuous
                professional development.
              </li>
            </ul>
            <p>
              All applicants must pass the CITB Health, Safety & Environmental
              test within 2 years prior to card application.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS Plant Machinery Marshal course is designed for
              individuals responsible for guiding and overseeing the safe
              operation of plant machinery. It ensures adherence to industry
              safety standards and provides comprehensive training in efficient
              machinery guidance and communication.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
