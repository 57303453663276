import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSideLoader() {
  return (
    <>
      <Helmet>
        <title>
          A15 Forklift Side-loader Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Master the operation of Forklift Side-loaders with the A15 CPCS Forklift Side-loader training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A15 Forklift Side-loader Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is aimed at individuals seeking a formal
              qualification for operating a Forklift Side-loader. Training
              duration will depend on learner experience, offering tailored
              options for beginners and experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the structure and maintenance of forklift
                side-loaders.
              </li>
              <li>Learning safety protocols and workplace regulations.</li>
              <li>
                Gaining hands-on experience in handling, lifting, and
                maneuvering loads safely.
              </li>
              <li>
                Pre-use checks, configuring for various tasks, and securing the
                machine post-operation.
              </li>
              <li>
                Identifying hazards, managing risks, and maintaining tidy
                workspaces.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a mix of classroom-based instruction and
              practical exercises, culminating in a one-day assessment through
              CPCS technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the CPCS technical tests, participants
              will receive a Red CPCS Trained Operator Card, valid for two
              years. Upgrading to a Blue CPCS Competence Card requires
              completing an NVQ Level 2 in Plant Operations within the two-year
              validity period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A15 Forklift Side-loader course provides a comprehensive blend
              of theoretical knowledge and practical skills to ensure safe and
              effective forklift operation. It is tailored to industry standards
              and prepares participants thoroughly for assessment.
            </p>
            <p>
              Enroll today to gain your industry-recognized certification and
              boost your career in material handling and logistics.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
