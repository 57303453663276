import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSExcavator180Below5T() {
  return (
    <>
      <Helmet>
        <title>A10 CPCS Excavator 180º Below 5 Tonnes Course</title>
        <meta
          name="description"
          content="Master the skills required to operate Excavators with the Excavator 180º below 5 tonnes - A10 training course at Alpha Construction Training. Achieve industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A10 CPCS Excavator 180º Below 5 Tonnes Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to equip participants with the knowledge
              and skills needed to safely and efficiently operate Excavators
              180º below 5 tonnes, in compliance with CPCS standards. It covers
              the key operational procedures and regulations associated with
              this type of machinery.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the nature of the construction industry and the
                roles and responsibilities of a plant operator.
              </li>
              <li>
                Identifying key components of an Excavator, including
                construction, controls, and terminology.
              </li>
              <li>
                Conforming to the manufacturer's requirements and relevant
                regulations.
              </li>
              <li>
                Performing pre-use checks and placing the excavator into
                service.
              </li>
              <li>Configuring and readying for site and highway travel.</li>
              <li>
                Travelling over rough, undulating ground, and steep inclines.
              </li>
              <li>
                Setting up for excavating duties and managing underground and
                overhead services.
              </li>
              <li>
                Excavating in various types of ground and handling materials.
              </li>
              <li>
                Shutting down procedures and preparing the machine for
                transport.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undergo both theoretical and practical
              assessments to demonstrate their ability to operate the Excavator
              safely and competently. The final assessment will include a CPCS
              technical test.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will receive the CPCS Red
              Trained Operator Card, which is valid for two years. They may also
              progress to obtain the CPCS Blue Competence Card by completing an
              NVQ Level 2.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The Excavator 180º below 5 tonnes - A10 course provides essential
              training for safe operation of excavators. This course offers a
              combination of theory and practical training to meet industry
              standards and prepare participants for a career in plant
              operation.
            </p>
            <p>
              Enroll today to earn your industry-recognized certification and
              enhance your career opportunities in the construction and plant
              operation industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
