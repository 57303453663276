import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSIndustrialForklift() {
  return (
    <>
      <Helmet>
        <title>
          A16 CPCS Industrial Forklift Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Learn about CPCS Industrial Forklift (A16) training with Alpha Construction Training. Gain practical skills, industry compliance, and CPCS certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A16 CPCS Industrial Forklift Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to equip operators with the necessary
              skills and knowledge to operate an industrial forklift safely and
              effectively. Whether you are an experienced operator or a novice,
              the training is tailored to meet your specific needs.
            </p>
            <p>
              The course ensures compliance with CPCS standards, preparing
              participants for real-world scenarios in industrial forklift
              operations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Identify and explain the purpose of principal components, basic
                construction, controls, and terminology of an industrial
                forklift.
              </li>
              <li>
                Comply with manufacturers’ requirements, operator handbooks, and
                relevant regulations and legislation.
              </li>
              <li>Perform pre-use checks and understand their importance.</li>
              <li>
                Configure the forklift for travel and navigate confined spaces,
                both with and without loads.
              </li>
              <li>
                Operate the forklift to lift, transfer, and place loads
                accurately at designated locations, including at full working
                height.
              </li>
              <li>
                Safely load and unload vehicles while maintaining a tidy work
                area.
              </li>
              <li>
                Understand actions for hazards and overhead/underground
                services.
              </li>
              <li>
                Carry out shutdown and securing procedures for safe storage and
                transport.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course involves a mixture of classroom-based theory sessions
              and hands-on practical exercises. The assessment consists of CPCS
              technical tests conducted over one day to evaluate your knowledge
              and practical skills.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the CPCS technical tests, learners
              will receive a Red CPCS Trained Operator Card, valid for two
              years. To upgrade to a Blue CPCS Competence Card, valid for five
              years, participants must complete an NVQ Level 2 in Plant
              Operations within the two-year period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This CPCS A16 Industrial Forklift Course provides a balanced mix
              of theoretical instruction and practical training. The course is
              designed for individuals seeking a formal qualification for
              operating an industrial forklift. With the guidance of experienced
              instructors, participants will gain the confidence and competence
              required to operate safely and efficiently.
            </p>
            <p>
              By completing this course, you open the door to a rewarding career
              in industrial forklift operations, supported by a widely
              recognized CPCS certification.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
