import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSMEWPSissor() {
  return (
    <>
      <Helmet>
        <title>A25 MEWP Scissor Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Join the A25 CPCS MEWP Scissor training course at Alpha Construction Training and become a certified operator in mobile elevating work platforms."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A25 MEWP Scissor Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is designed to equip participants with
              the knowledge and practical skills necessary to safely operate a
              MEWP Scissor in compliance with industry standards and
              regulations.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the nature of the industry and the role of an access
                operator.
              </li>
              <li>
                Identify and explain the purpose of principal components,
                construction, controls, and terminology.
              </li>
              <li>
                Follow manufacturer requirements and relevant regulations as
                outlined in the operator’s handbook.
              </li>
              <li>
                Perform all pre-use checks and emergency lowering function
                tests.
              </li>
              <li>
                Configure and set the machine for site travel over level
                surfaces and inclines.
              </li>
              <li>
                Travel safely over rough terrain, inclines, and confined spaces.
              </li>
              <li>
                Set up and operate the platform for accessing work positions.
              </li>
              <li>
                Access working points at full operating height while observing
                safety protocols.
              </li>
              <li>
                Utilize extension units and handle travel with a raised platform
                (where applicable).
              </li>
              <li>
                Understand actions required for hazards, underground and
                overhead services.
              </li>
              <li>Carry out proper shut-down and securing procedures.</li>
              <li>
                Explain and perform correct loading and unloading procedures for
                transport.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course includes a combination of classroom-based instruction
              and practical exercises, culminating in a one-day assessment
              through CPCS technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successfully passing the CPCS technical tests, learners will
              receive a Red CPCS Trained Operator Card, valid for two years. To
              upgrade to a Blue CPCS Competence Card, participants must complete
              an NVQ Level 2 in Plant Operations specific to scissor-type mobile
              elevating work platforms within the two-year period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A25 MEWP Scissor course is a comprehensive program combining
              theoretical and practical training to ensure participants are
              proficient in operating MEWP Scissors safely and effectively. With
              expert guidance and industry-aligned training, this course
              prepares you for certification and a successful career in the
              construction sector.
            </p>
            <p>
              Enroll today to achieve your CPCS certification and unlock new
              opportunities in mobile elevating work platform operations.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
