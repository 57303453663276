import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSMotorisedScraper() {
  return (
    <>
      <Helmet>
        <title>
          A24 Motorised Scraper Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join the A24 CPCS Motorised Scraper training course at Alpha Construction Training and become a certified operator in motorised scraper machinery."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A24 Motorised Scraper Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              Embark on a journey to become proficient in handling motorised
              scraper equipment. This course provides essential skills and
              knowledge needed to operate motorised scrapers safely and
              efficiently, catering to both novices and experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the name and function of principal components and
                attachments of motorised scrapers.
              </li>
              <li>
                Learn safe operation, pre-use checks, and configuring the
                equipment for various duties and ground conditions.
              </li>
              <li>
                Develop skills in configuring and setting up for site travel
                over diverse terrain, both laden and unladen.
              </li>
              <li>
                Master the techniques for safe loading, unloading, and managing
                correct load ejection heights.
              </li>
              <li>
                Identify and manage hazards, including underground and overhead
                services.
              </li>
              <li>
                Understand procedures for site preparation, extraction, and
                loading duties.
              </li>
              <li>
                Learn to backfill, spread, and level ground and materials
                effectively.
              </li>
              <li>Perform safe shutdown and securing procedures.</li>
              <li>
                Maintain safe and tidy working areas, adhering to best practices
                and safety protocols.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course involves a combination of classroom-based teaching and
              practical exercises, followed by a one-day assessment through CPCS
              technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful candidates will receive a Red CPCS Trained Operator
              Card, valid for two years. To upgrade to a Blue CPCS Competence
              Card, participants must complete an NVQ Level 2 in Plant
              Operations within the two-year validity period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A24 Motorised Scraper course equips participants with the
              theoretical and practical knowledge required to operate motorised
              scrapers safely and efficiently. With a comprehensive curriculum
              and expert guidance, learners will be prepared for certification
              and a successful career in construction machinery operation.
            </p>
            <p>
              Enroll today to achieve your CPCS certification and unlock new
              opportunities in the construction industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
