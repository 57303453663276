import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function RideOnRoller() {
  return (
    <>
      <Helmet>
        <title>N214 Ride-On Roller Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn to operate ride-on rollers safely and effectively with our NPORS Ride-on Roller (N214) course. Suitable for all experience levels."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N214 Ride-On Roller Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed for individuals seeking formal
              qualifications for operating ride-on rollers. Whether you have
              limited experience or are a seasoned operator, we cater to your
              specific needs. Experienced operators can opt for a one-day
              Experienced Worker Assessment (Test Only), while less experienced
              candidates will undergo a tailored training program based on a
              learner analysis.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Gain a basic understanding of the construction industry, its
                associated risks, and operator responsibilities.
              </li>
              <li>
                Develop a working knowledge of the manufacturer’s handbook for
                the ride-on roller.
              </li>
              <li>
                Locate and identify the roller's major components and controls,
                and explain their functions.
              </li>
              <li>
                Conduct pre-operational checks in accordance with manufacturer’s
                and legislative requirements.
              </li>
              <li>
                Identify and correctly use PPE specific to ride-on roller
                operations.
              </li>
              <li>
                Prepare the roller for use and operate it safely and
                efficiently.
              </li>
              <li>Conduct essential safety checks at the worksite.</li>
              <li>Address environmental considerations during operation.</li>
              <li>
                Demonstrate proper loading and unloading procedures for machine
                transportation.
              </li>
              <li>Perform end-of-shift and shutdown procedures accurately.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <ul>
              <li>
                <strong>Theory Assessment:</strong> Includes open questions and
                multiple-choice questions.
              </li>
              <li>
                <strong>Practical Assessment:</strong> Evaluate the operator's
                ability to use the ride-on roller effectively and safely.
              </li>
            </ul>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <ul>
              <li>
                <strong>Existing NPORS Card Holder – No (S)NVQ Held:</strong>{" "}
                Operators without the requisite (S)NVQ will receive a Trained
                Operator card valid for two years. They must achieve the
                requisite (S)NVQ within this period to retain their credentials.
              </li>
              <li>
                <strong>Existing NPORS Card Holder – (S)NVQ Held:</strong>{" "}
                Operators with the requisite (S)NVQ will be issued a Competent
                Operator card valid for five years. A CPD record and operator
                logbook will also be provided to document ongoing professional
                development and operating hours.
              </li>
            </ul>
            <p>
              Please note: Proof of passing the CITB Health, Safety &
              Environment touch screen test within two years prior to
              application is required in all cases.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS Ride-On Roller course is suitable for operators with
              varying levels of experience. The training program emphasizes
              safety, operational efficiency, and adherence to industry best
              practices, providing candidates with the necessary skills to
              operate ride-on rollers safely and competently.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
