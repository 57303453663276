import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function MarineKnuckleBoomCrane() {
  return (
    <>
      <Helmet>
        <title>
          N014 Marine Knuckle Boom Crane Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Enroll in our NPORS Marine Knuckle Boom Crane Training Course to gain expertise in safe and efficient crane operations onboard marine vessels."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N014 Marine Knuckle Boom Crane Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course provides comprehensive training and assessment in the
              operation and maintenance of Marine Knuckle Boom Cranes. It is
              suitable for individuals with no formal instruction or those
              seeking to refresh their skills through a shorter duration course.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the fundamentals of offshore crane lifting
                operations, including the associated dangers and
                responsibilities as an operator.
              </li>
              <li>
                Gain knowledge of the manufacturer's handbook for the specific
                crane being used.
              </li>
              <li>Interpret lift plans and risk assessments effectively.</li>
              <li>
                Identify and explain the major components and key controls of
                the crane.
              </li>
              <li>
                Set up exclusion zones and identify necessary emergency actions
                and hazards.
              </li>
              <li>
                Select and maintain appropriate personal protective equipment
                (PPE) for crane operations.
              </li>
              <li>
                Perform pre-operational and running checks in line with
                manufacturer and legislative requirements.
              </li>
              <li>
                Inspect and ensure the suitability of landing areas for crane
                operations.
              </li>
              <li>
                Prepare the crane for lifting duties, including over-side lifts.
              </li>
              <li>
                Operate the crane safely and efficiently, considering sea uplift
                and moving vessel decks.
              </li>
              <li>Address environmental considerations during operations.</li>
              <li>Execute end-of-work and shutdown procedures effectively.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course combines practical exercises with classroom-based
              tutorials. Assessment includes both theoretical and practical
              tests to evaluate competency in crane operations.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful participants will receive the NPORS Operator Card upon
              completing the course assessments. The card is a recognized
              certification that validates your operational competency.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS Marine Knuckle Boom Crane Training Course provides
              foundational training for operators in marine environments.
              Designed for beginners and experienced individuals seeking skill
              updates, this course ensures participants can operate cranes
              safely and efficiently. With competitive pricing and expert
              instruction, it prepares attendees for real-world challenges and
              certification success.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
