import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CableAvoidanceTool() {
  return (
    <>
      <Helmet>
        <title>N304 Cable Avoidance Tools Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Gain the skills needed for Cable Avoidance Tool (CAT) operation with Alpha Constructions. This one-day NPORS assessment covers all aspects of safe operation and inspection."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N304 Cable Avoidance Tools Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This assessment is designed for experienced workers who already
              possess the necessary knowledge and hands-on experience to pass
              both the theoretical and practical components required for the
              NPORS Operator Card. If additional preparation is needed, pre-test
              training can be arranged.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Your responsibilities under the Health & Safety at Work Act,
                1974, and other relevant legislation.
              </li>
              <li>
                Understanding all aspects of HSG47, including guidelines and
                regulations.
              </li>
              <li>
                Overview of the Provision & Use of Work Equipment Regulations.
              </li>
              <li>Familiarity with the Manual Handling Regulations.</li>
              <li>
                Understanding the Personal Protective Equipment (PPE)
                Regulations.
              </li>
              <li>
                How to operate the Cable Avoidance Tool (CAT), including all
                controls.
              </li>
              <li>
                How to operate the signal generator (GENNY) and its functions.
              </li>
              <li>
                Correct use of the equipment in Power, Radio, and generator
                modes (may vary by type).
              </li>
              <li>Testing, inspecting, and maintaining the CAT equipment.</li>
              <li>
                Safe and effective procedures for searching, pinpointing, and
                tracing underground services.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment consists of a theory test made up of
              multiple-choice questions and open questions. In addition, there
              is a practical test where participants demonstrate their
              proficiency in using the Cable Avoidance Tool (CAT).
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              After completing the assessment, candidates may apply for the
              NPORS Traditional Card. For those wishing to have the ‘HS&E
              TESTED’ logo on the card, they must pass the CITB Health, Safety &
              Environment Test within two years prior to their application. The
              card is valid for 3 or 5 years, depending on the type.
            </p>
            <p>
              New entrants without an NVQ will receive a Trained Operator card,
              valid for 2 years, and must complete the requisite NVQ within that
              period to upgrade to a Competent Operator card, valid for 5 years.
              Operators who already hold the required NVQ will receive a
              Competent Operator card valid for 5 years upon passing the
              assessment.
            </p>
            <p>
              In all cases, operators will need to provide proof of having
              passed the CITB Health, Safety & Environmental test within the
              last two years prior to applying for the NPORS card.
            </p>
            <p>
              NPORS cards can be issued with either individual expiry dates for
              each category or a single expiry date for all categories held.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              This one-day assessment ensures participants are proficient in the
              operation and maintenance of the Cable Avoidance Tool (CAT). The
              training combines both theory and practical elements, reinforcing
              safe working practices and industry standards.
            </p>
            <p>
              Upon successful completion, participants will receive an NPORS
              certification, demonstrating their competency in cable avoidance
              and underground service detection.
            </p>
          </div>
          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
