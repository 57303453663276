import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSExcavator360Below10T() {
  return (
    <>
      <Helmet>
        <title>
          A58 Excavator 360º Below 10 Tonne Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join our CPCS Excavator 360 Below 10 Tonne Training course to gain essential skills and certification for operating mini excavators."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A58 Excavator 360º Below 10 Tonne Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is designed to provide participants with
              the skills and knowledge required to operate mini excavators
              safely and productively. It caters to individuals with varying
              levels of experience, offering tailored training durations based
              on skill levels.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>Safe and productive operation of mini excavators.</li>
              <li>Fitting and using ancillary equipment effectively.</li>
              <li>
                Performing minor adjustments and routine servicing procedures.
              </li>
              <li>
                Understanding the capabilities, purposes, and limitations of
                mini excavators.
              </li>
              <li>
                Adhering to necessary safety precautions during operation.
              </li>
              <li>
                Interpreting information from level pegs and profiles
                accurately.
              </li>
              <li>Utilizing laser equipment to work to precise levels.</li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The assessment includes a mix of classroom-based teaching and
              practical exercises. The course concludes with a CPCS technical
              test, evaluating both theoretical knowledge and practical skills.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Participants who pass the CPCS technical tests will receive the
              CPCS Red Trained Operator Card, valid for two years. To progress
              to the Blue Competence Card, learners must complete an NVQ Level 2
              in Plant Operations within this timeframe.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Excavator 360 Below 10 Tonne Training Course equips
              participants with essential operational skills and
              industry-recognized certification. Join Alpha Construction
              Training to advance your career in construction machinery
              operation.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
