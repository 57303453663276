import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSExcavator180Above5T() {
  return (
    <>
      <Helmet>
        <title>A12 CPCS Excavator 180º Above 5 Tonnes Course</title>
        <meta
          name="description"
          content="Gain expertise in operating Excavators with the Excavator 180º above 5 tonnes - A12 training course at Alpha Construction Training. Achieve industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A12 CPCS Excavator 180º Above 5 Tonnes Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is tailored to provide participants with the skills
              and knowledge required to operate 180º excavators above 5 tonnes
              safely and effectively. Participants will learn industry best
              practices and regulations to enhance their operational competence.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the construction industry's nature and the operator's
                roles and responsibilities.
              </li>
              <li>
                Identify key components of 180º excavators, including
                construction, controls, and terminology.
              </li>
              <li>
                Adhere to manufacturer’s guidelines, operator manuals, and
                relevant regulations.
              </li>
              <li>
                Perform comprehensive pre-use checks and basic maintenance.
              </li>
              <li>Prepare the excavator for site and highway travel.</li>
              <li>
                Navigate and operate over various terrains, including steep
                inclines and confined spaces.
              </li>
              <li>
                Execute excavation tasks, load materials, and perform grading
                and leveling.
              </li>
              <li>Safely attach and remove buckets and other components.</li>
              <li>
                Shut down the machine and ensure proper transportation
                procedures are followed.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes theoretical and practical assessments.
              Participants must demonstrate their ability to operate the
              excavator safely and efficiently, culminating in a CPCS technical
              test.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants receive the CPCS Red
              Trained Operator Card, valid for two years. Progression to the
              CPCS Blue Competence Card is possible through completion of an NVQ
              Level 2.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A12 Excavator 180º above 5 tonnes course combines theoretical
              and practical training to meet industry standards. Participants
              will develop critical operational skills necessary for safe and
              productive plant operation.
            </p>
            <p>
              Enroll now to gain an industry-recognized certification and
              enhance your qualifications in the construction and plant
              operation field.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
