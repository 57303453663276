import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function LogHandlerCourse() {
  return (
    <>
      <Helmet>
        <title>N106 Log Handler Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Learn to operate log handling equipment safely and efficiently with NPORS-certified N106 Log Handler training at Alpha Constructions. Gain essential skills and certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N106 Log Handler Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is tailored to equip participants with the skills and
              knowledge to safely and effectively operate log handling
              equipment. It caters to individuals at all levels, from novices to
              experienced operators seeking to refresh their expertise.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understanding the logging and forestry industry, including
                operator responsibilities and potential hazards.
              </li>
              <li>
                Comprehensive knowledge of the manufacturer’s handbook for the
                specific log handler being used.
              </li>
              <li>
                Identifying and understanding the major components and controls
                of the machine.
              </li>
              <li>
                Conducting pre-operational inspections in line with
                manufacturer’s and legislative requirements.
              </li>
              <li>
                Safely mounting and dismounting the machine during operations.
              </li>
              <li>
                Operating the log handler on various terrains with safety and
                efficiency.
              </li>
              <li>
                Performing essential safety checks within the work environment.
              </li>
              <li>
                Handling, loading, and unloading logs onto transportation
                vehicles.
              </li>
              <li>Attaching and detaching implements effectively.</li>
              <li>
                Completing end-of-shift procedures and safely shutting down the
                machine.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undergo a comprehensive assessment, consisting
              of a theory examination with open and multiple-choice questions,
              as well as a practical evaluation to demonstrate their ability to
              operate the log handler in real-world scenarios.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the course, participants will
              receive an NPORS ID card, valid for 5 years. This certification
              validates the operator’s competency and readiness to work in the
              forestry and timber industries.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The NPORS N106 Log Handler Training combines theoretical
              instruction with practical, hands-on experience. It is ideal for
              individuals aiming to develop or enhance their skills in the
              logging and forestry sector. The course emphasizes safety, proper
              operation, and maintenance to ensure efficiency and compliance
              with industry standards.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
