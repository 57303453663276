import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSSlingerSignaller() {
  return (
    <>
      <Helmet>
        <title>
          Slinger Signaller A40 Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join the CPCS Slinger Signaller A40 training course at Alpha Construction Training to gain essential skills for safe and effective lifting operations."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>Slinger Signaller A40 Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This Slinger Signaller CPCS training course is aimed at
              individuals seeking entry into the lifting industry. It equips
              participants with the skills and knowledge needed to carry out
              safe and effective slinging and signalling operations across
              various lifting scenarios.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Establish the weight and dimensions of loads and communicate
                this information to the crane operator.
              </li>
              <li>
                Select appropriate lifting tackle and attach and detach it
                correctly and safely.
              </li>
              <li>
                Use clear and unambiguous signals to direct crane movements
                while handling loads of various weights and dimensions.
              </li>
              <li>
                Determine the serviceability of lifting accessories and ensure
                their proper maintenance and storage.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes a mixture of classroom-based teaching and
              practical exercises, followed by a one-day assessment comprising
              CPCS technical tests. These tests evaluate your theoretical
              knowledge and practical competence in slinging and signalling
              operations.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion of the technical tests, learners will
              receive a Red CPCS Trained Operator Card, valid for two years. To
              upgrade to a Blue CPCS Competence Card, participants must complete
              an NVQ Level 2 in Controlling Lifting Operations - Slinger
              Signaller within this two-year period. The Blue Card is renewable
              every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Slinger Signaller A40 course is tailored to provide a
              comprehensive understanding of slinging and signalling practices.
              It caters to both novice and experienced operators, ensuring all
              participants are equipped to handle lifting operations efficiently
              and safely.
            </p>
            <p>
              Enroll today to gain essential skills and certifications required
              to thrive in the lifting industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
