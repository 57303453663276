import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSWheeledLoadingShovel() {
  return (
    <>
      <Helmet>
        <title>
          A21 Wheeled Loading Shovel Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Gain formal qualifications with the A21 CPCS Wheeled Loading Shovel training course at Alpha Construction Training."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A21 Wheeled Loading Shovel Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This CPCS training course is aimed at people who require a formal
              qualification for operating a Wheeled Loading Shovel. Training
              duration will be determined through a learner analysis to
              accommodate both beginners and experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Undertake all pre-start, running checks, and shut down
                procedures.
              </li>
              <li>Safely mount and dismount the equipment.</li>
              <li>
                Explain precautions to be taken for underground and overhead
                services and other hazards.
              </li>
              <li>
                Prepare and configure the wheeled loading shovel for site and
                road travel.
              </li>
              <li>
                Place the machine in an out-of-service condition securely.
              </li>
              <li>
                Carry out excavations from an embankment of different materials
                and form stockpiles and ramps.
              </li>
              <li>Ensure load integrity and security during operations.</li>
              <li>
                Explain loading and unloading procedures for machine
                transportation.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              The course includes classroom-based teaching and practical
              exercises, followed by a one-day assessment consisting of CPCS
              technical tests.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Learners will receive a Red CPCS Trained Operator Card upon
              successfully passing the technical tests, valid for two years. To
              upgrade to a Blue CPCS Competence Card, participants must complete
              an NVQ Level 2 in Plant Operations within the two-year validity
              period.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A21 Wheeled Loading Shovel course provides comprehensive
              training to ensure participants are equipped with the knowledge
              and skills required for safe and effective equipment operation.
              Combining theoretical and practical elements, this training
              prepares learners for certification and workplace success.
            </p>
            <p>
              Enroll today to achieve your CPCS certification and advance your
              career in equipment operation and site management.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
