import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSLiftingOperations() {
  return (
    <>
      <Helmet>
        <title>
          A61 CPCS Lifting Operations Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Gain expertise in lifting operations with the CPCS Appointed Person (A61) course at Alpha Construction Training. Achieve industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A61 CPCS Lifting Operations Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is specifically designed to equip participants with
              the skills and knowledge required to effectively plan and manage
              lifting operations in accordance with CPCS standards. It ensures
              participants understand legislative requirements and industry best
              practices, enabling them to take on the responsibilities of an
              appointed person.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Comprehensive understanding of legal frameworks and regulations
                governing lifting operations.
              </li>
              <li>
                Roles and responsibilities of personnel involved in lifting
                operations, including the appointed person, lifting supervisor,
                and others.
              </li>
              <li>
                Identification and application of various types of lifting
                equipment and their capabilities and limitations.
              </li>
              <li>
                Detailed knowledge of lifting accessories, including
                inspections, maintenance, and capacity calculations.
              </li>
              <li>
                Planning and executing basic, standard, and complex lifting
                operations safely and efficiently.
              </li>
              <li>
                Managing site-specific hazards and ensuring compliance with
                safety protocols for lifting operations.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undergo both theoretical instruction and
              practical exercises. The course concludes with a CPCS technical
              test that assesses understanding and competency in planning and
              managing lifting operations.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will receive the CPCS Red
              Trained Operator Card, valid for two years. During this time, they
              can achieve an NVQ Level 5 Diploma, which leads to the CPCS Blue
              Competence Card, renewable every five years.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Appointed Person (A61) Course provides a robust
              foundation in the legal, technical, and practical aspects of
              managing lifting operations. It combines detailed theoretical
              instruction with hands-on training to ensure participants are
              fully prepared to take on the responsibilities of an appointed
              person.
            </p>
            <p>
              Enroll today to gain industry-recognized certification and advance
              your career in the construction sector.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
