import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSCrawlerSideBoom() {
  return (
    <>
      <Helmet>
        <title>
          A35 Crawler Tractor / Side Boom Course | Alpha Construction Training
        </title>
        <meta
          name="description"
          content="Join the CPCS Crawler Tractor / Side Boom A35 training course at Alpha Construction Training to gain essential skills and certification for operating crawler tractors and side booms."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A35 Crawler Tractor / Side Boom Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              The CPCS Crawler Tractor / Side Boom A35 Training Course is
              designed to provide participants with the essential knowledge and
              hands-on skills required for safe and efficient operation of
              crawler tractors and side booms. This course caters to both
              beginners and experienced operators.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understand the roles and responsibilities of a plant operator in
                the construction sector.
              </li>
              <li>
                Familiarize yourself with the principal components, basic
                construction, controls, and terminology of crawler tractors and
                side booms.
              </li>
              <li>
                Conduct pre-use checks and comply with manufacturer requirements
                and regulations.
              </li>
              <li>
                Configure and set the crawler tractor and side boom for site
                travel and operational duties.
              </li>
              <li>
                Safely maneuver over rough terrain, inclines, and confined
                spaces.
              </li>
              <li>
                Perform tasks such as lifting, positioning, and handling loads
                with the side boom.
              </li>
              <li>
                Understand hazards, underground and overhead services, and how
                to mitigate risks effectively.
              </li>
              <li>Perform shut down and secure the equipment after use.</li>
              <li>
                Learn loading and unloading procedures for machine transport.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              This course includes a combination of classroom-based teaching and
              practical exercises, followed by a competency assessment
              comprising CPCS technical tests. Participants will demonstrate
              their proficiency in operation and safety practices.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Successful participants will receive a Red CPCS Trained Operator
              Card, valid for two years. To upgrade to a Blue CPCS Competence
              Card, participants must complete an NVQ Level 2 in Plant
              Operations within two years of obtaining the Red Card.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The CPCS Crawler Tractor / Side Boom A35 Training Course offers a
              blend of theoretical and practical training to prepare operators
              for real-world challenges. Whether you are new to this equipment
              or looking to enhance your skills, this course provides a
              comprehensive pathway to certification and career advancement.
            </p>
            <p>
              Enroll today to take your construction career to the next level
              with our expert training and industry-recognized certification.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
