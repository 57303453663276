import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function CPCSTowerCrane() {
  return (
    <>
      <Helmet>
        <title>A04 Tower Crane Course | Alpha Construction Training</title>
        <meta
          name="description"
          content="Master the skills required to operate Tower Cranes with the A04 CPCS Tower Crane training course at Alpha Construction Training. Achieve industry-recognized certification."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>A04 Tower Crane Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course is designed to equip participants with the knowledge
              and skills needed to safely and efficiently operate Tower Cranes
              in compliance with CPCS standards. It covers the key operational
              procedures and regulations associated with this type of machinery.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You'll Learn</h2>
            <ul>
              <li>
                Understanding the nature of the construction industry and the
                roles and responsibilities of a plant operator.
              </li>
              <li>
                Identifying key components of a Tower Crane, including
                construction, controls, and terminology.
              </li>
              <li>
                Conforming to the manufacturer's requirements and relevant
                regulations.
              </li>
              <li>
                Performing pre-use checks and placing the crane into service.
              </li>
              <li>
                Setting up and configuring the crane for lifting duties and
                handling loads safely.
              </li>
              <li>
                Understanding the various lifting accessories and their
                applications.
              </li>
              <li>
                Carrying out safe lifting practices, including load balancing
                and placement.
              </li>
              <li>
                Demonstrating proper crane travel techniques and minimizing load
                swinging.
              </li>
              <li>
                Understanding the impact of weather conditions, such as wind, on
                load handling.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undergo both theoretical and practical
              assessments to demonstrate their ability to operate the Tower
              Crane safely and competently. The final assessment will include a
              CPCS technical test.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              Upon successful completion, participants will receive the CPCS Red
              Trained Operator Card, which is valid for two years. They may also
              progress to obtain the CPCS Blue Competence Card by completing an
              NVQ Level 2.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The A04 CPCS Tower Crane course provides essential training in
              safely operating tower cranes. The combination of theory and
              practical training ensures that participants meet industry
              standards and are well-prepared for a career in crane operation.
            </p>
            <p>
              Enroll today to earn your industry-recognized certification and
              enhance your career opportunities in the construction and plant
              operation industry.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
