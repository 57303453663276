import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import "../../css/machine.css";
import { Helmet } from "react-helmet";

export default function HarnessFallArrest() {
  return (
    <>
      <Helmet>
        <title>N723 Harness and Fall Arrest Course | Alpha Constructions</title>
        <meta
          name="description"
          content="Enroll in Alpha Constructions' NPORS-certified Harness and Fall Arrest training. Learn essential skills for safe and effective use of harnesses and fall arrest equipment."
        />
      </Helmet>
      <Navbar />
      <div className="machine-container">
        <div className="machine-hero">
          <h1>N723 Harness and Fall Arrest Course</h1>
        </div>

        <div className="machine-content">
          <div className="course-objective">
            <h2>Course Objective</h2>
            <p>
              This course provides participants with the knowledge and practical
              skills needed to safely and effectively use harness and fall
              arrest equipment. It focuses on industry standards, safe work
              practices, and compliance with legislative requirements.
            </p>
          </div>

          <div className="learning-outcomes">
            <h2>What You Will Learn</h2>
            <ul>
              <li>
                Understanding the dangers of working in the industry and the
                responsibilities of operators.
              </li>
              <li>
                Familiarity with the manufacturer's handbook for the specific
                harness or fall arrest being used.
              </li>
              <li>
                Identification of major components of the harness/fall arrest
                and understanding their functions.
              </li>
              <li>
                Conducting safety checks in line with manufacturer and
                legislative guidelines.
              </li>
              <li>
                Selecting the appropriate harness/fall arrest type for the work
                being undertaken.
              </li>
              <li>
                Identifying and maintaining the correct PPE for using harness or
                fall arrest systems and demonstrating its proper use.
              </li>
              <li>
                Fitting and removing the safety harness/fall arrest correctly.
              </li>
              <li>Carrying out end-of-work inspections.</li>
              <li>
                Considering environmental factors and ensuring proper storage of
                harnesses or fall arrest systems.
              </li>
            </ul>
          </div>

          <div className="assessment">
            <h2>Assessment</h2>
            <p>
              Participants will undergo an NPORS theory assessment, comprising
              open questions and multiple-choice questions, as well as a
              practical test to evaluate their competency in using the harness
              or fall arrest system.
            </p>
          </div>

          <div className="certification">
            <h2>Certification</h2>
            <p>
              <strong>NPORS (Traditional) Operator Card:</strong> This card is
              suitable for sectors outside of construction that do not require
              the CSCS logo. The standards for achieving this certification are
              the same as those for the NPORS/CSCS card.
            </p>
            <p>
              End users have the option to choose between 3- or 5-year
              registration periods, depending on sector-specific or company
              requirements.
            </p>
            <p>
              <strong>Important Note:</strong> For those operating within the
              construction sector, the NPORS/CSCS card route must be followed.
            </p>
          </div>

          <div className="course-summary">
            <h2>Course Summary</h2>
            <p>
              The N723 Harness and Fall Arrest Training Course equips
              participants with essential skills to safely use harnesses and
              fall arrest systems. The training emphasizes safety, legislative
              compliance, and best practices, culminating in NPORS
              certification. Operators working in the construction sector should
              pursue the NPORS/CSCS card to meet industry requirements.
            </p>
          </div>

          <div className="contact-us-section">
            <button
              className="contact-us-button"
              onClick={() => (window.location.href = "/contactus")}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
